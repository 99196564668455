import axios from "axios";

// TODO Should be fetched form the env file
//https://adminv1.meemorder.io/api/resto/by/name/cima

// const baseURL = "https://adminv1.meemorder.io/api/"; // old url

export const baseURL = 'https://orderapi.dukany.io/api/'; // new url

// export const baseURL = 'https://devsrvorderapi.dukany.io/api/';//dev server

export const instance = axios.create({
  baseURL,
  timeout: 100000,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default instance;
