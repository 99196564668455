import React from "react";
// import { ReactComponent as DoneSvg } from "../../../assets/images/done-icon.svg";
interface DoneSvgProps {
  height: number | string;
  width: number | string;
  wrapperHeigth: number | string;
  wrapperWidth: number | string;
  wrapperBgColor: string;
}
const DoneIconSvg: React.FC<DoneSvgProps> = (props: DoneSvgProps) => {
  const { height, width, wrapperWidth, wrapperHeigth, wrapperBgColor } = props;
  return (
    <div
      className="tick-svg-wrapper"
      style={{
        height: wrapperHeigth,
        width: wrapperWidth,
        backgroundColor: wrapperBgColor ? wrapperBgColor : "#4bae4f",
        // backgroundColor: wrapperBgColor,
      }}
    >
      <svg
        style={{ paddingRight: 4, paddingTop: 1, paddingLeft: 4 }}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="#ffffff"
        className="bi bi-check2"
        viewBox="0 0 16 16"
      >
        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
      </svg>
    </div>
  );
};

export default DoneIconSvg;
