import React from "react";
interface ErrorModalProps {
  onPressClose: () => void;
  errorMessage: string;
  buttonMessage: string;
  headerMessage: string;
  onBackDropClick?: () => void;
}
const ErrorModal: React.FC<ErrorModalProps> = (props: ErrorModalProps) => {
  const {
    onPressClose,
    errorMessage,
    onBackDropClick,
    buttonMessage,
    headerMessage,
  } = props;
  const closeModal = () => {
    onPressClose();
  };
  return (
    <div
      onClick={onBackDropClick}
      id="ErroModal"
      style={{
        background: "rgba(0, 0, 0, 0.4)",
      }}
      className="pop_up_maps_main_error_modal pop_main_menu_bg location-error-modal"
    >
      <div
        // style={{ bottom: "auto", top: 0 }}
        style={{
          marginLeft: 20,
          alignSelf: "center",
          bottom: "auto",
          // borderRadius: '0px',
          /* margin: 0px 10px; */
          width: "90%",

          borderRadius: "10px",
          zIndex: 2,
        }}
        //popup_slide
        className="popup_delivery popup_from_bottom pop_time_set_height "
      >
        <div
          className="content"
          id=""
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          <div
          // className="container"
          >
            <div className="container">
              <div style={{ borderBottom: 0 }} className="head_burg_menu">
                <div className="calender_header">
                  {/* <div className="calender_close">
                    <a
                      onClick={closeModal}
                      className="slide_remove"
                      //  href="#Accordion_tabs"
                    >
                      &times;
                    </a>
                  </div> */}
                </div>
                <div className="delivery_head">{headerMessage}</div>
                <div className="delivery_time_text" style={{ marginTop: 10 }}>
                  {errorMessage}
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* <br /> */}
          {/* <br />
          <br /> */}
          <div
            onClick={closeModal}
            style={{
              display: "flex",
              width: "90%",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginLeft: 13,
              marginRight: 13,
              marginBottom: 20,
              height: "4.5rem",
              borderRadius: 4,
            }}
            className="background-color-trash "
          >
            <span style={{ alignItems: "center" }}>{buttonMessage}</span>
          </div>
        </div>

        {/* <BottomButton
          onPress={closeModal}
          titleProp="Change Location"
          widthProp={"375px"}
        /> */}
      </div>
    </div>
  );
};

export default ErrorModal;
