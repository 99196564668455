/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../HomePage/styles.css";
import {
  IPLACEORDER,
  IProxyIp,
  ObjectKey,
  StoreData,
  social_name,
} from "../HomePage/utils";
import useCustomerInfo from "../../hooks/useCustomerInfo";
import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import "./basket-styles.css";
import moment from "moment";
import MessagePage from "../MessagePage";
import "react-phone-input-2/lib/style.css";
import useSendOtp from "../../hooks/useSendOtp";
import { SingleItem } from "../HomePage/utils";
import Combobox from "react-widgets/Combobox";
import {
  ALL_COUNTRIES_ARRAY,
  TrackGoogleAnalyticsEvent,
  getReqJsonModalGA4,
  getReqJsonModalGA4WithoutReqData,
  numberWithCommas,
} from "../utilis";
import "react-widgets/styles.css";
// import PopConfirmPhone from "../ConfirmPhone/PopConfirmPhone";
import { useNavigate, useParams } from "react-router-dom";
import { ObjectKeyLng } from "../HomePage/utils";
import { translations } from "../../../assets/meemLabels";
import PopConfirmPhone from "../ConfirmPhone/PopConfirmPhone";
import PopPageValidateOtp from "../ValidateOtp/PopPageValidateOtp";
import PlusIcon from "../components/PlusIcon";
import BlockSelectedItem from "../components/BlockSelectedItem";
import EmptyCart from "../components/EmptyCart";
import ErrorModal from "../shared/ErrorModal";
interface BasketPageProp {
  onPressClose: (addNew: boolean) => void;
  itemDetail?: SingleItem;
  restoToken: string;
  restoName: string;
  ipAddressProp: string;
  socailType: string;
  userIpProxy: IProxyIp;
  userCCProp: string;
  restoData?: StoreData;
  selectSizeProp: string;
  selectColorProp: string;
  cartValues: any;
}
const BasketPage: React.FC<BasketPageProp> = (props: BasketPageProp) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const { sendOtpHandler, otpData } = useSendOtp();
  const { bussinessName, socailName } = useParams();
  const navigate = useNavigate();
  const {
    onPressClose,
    restoToken,
    itemDetail,
    ipAddressProp,
    userCCProp,
    socailType,
    restoData,
    userIpProxy,
  } = props;
  const shopCartFromStrg = localStorage.getItem("singlePage/" + bussinessName);
  const [shopCartStrg, setShopCartStrg] = useState(
    localStorage.getItem("shopCart")
  );
  const customerLocalInfo = localStorage.getItem("customerInfo");
  const customerLocalInfoParsed =
    customerLocalInfo !== null && JSON.parse(customerLocalInfo);
  const defaultLang: ObjectKeyLng =
    shopCartFromStrg !== null && JSON.parse(shopCartFromStrg).default_lang;

  const [userName, setUserName] = useState<string>("");
  const [showArrayErr, setShowArrayErr] = useState<boolean>(false);
  const [showNameErr, setShowNameErr] = useState<boolean>(false);
  const [showPhoneErr, setShowPhoneErr] = useState<boolean>(false);
  const [selectedCC, setSelectedCC] = useState<string>("");
  const [showDeliveryErr, setShowDeliveryErr] = useState<boolean>(false);
  const [errAddMore, setErrAddMore] = useState<boolean>(false);
  const [phoneNumberLenght, setPhoneNumberLength] = useState<number>(0);
  const [disablePlaceOrder, setDisablePlaceOrder] = useState<boolean>(false);
  const [showNumberErr, setShowNumberErr] = useState<boolean>(false);
  const [deliveryCharges, setDeliveryChargers] = useState<string>("");
  const [userPhone, setUserPhone] = useState<string>("");
  const [showName, setShowName] = useState<boolean>(true);
  const [showMobileModal, setShowMobileModal] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  // var redirectDomain = "https://b.meemorder.io/";
  // order.dukany.io
  var redirectDomain = "https://order.dukany.io/";
  const [basketValues, setBasketValues] = useState<any>([]);
  const [currency, setCurrency] = useState<string>(
    restoData!.resto_meta?.BUSSINESS_CCY_LANG[defaultLang]
  );
  const {
    loading,
    getCustomerInfo,
    customerInfo,
    placeOrder,
    orderPlaced,
    success,
  } = useCustomerInfo();
  /** google object format 
  function getEcomSampleCart() {
    var trnid = "T_" + Math.random().toString(16).slice(2);
    var item1Price = randomIntFromInterval(10, 50);
    var item1Discount = 0.1 * item1Price;
    var item1Qty = randomIntFromInterval(10, 50);

    var item2Price = randomIntFromInterval(40, 100);
    var item2Discount = 0.2 * item2Price;
    var item2Qty = randomIntFromInterval(10, 50);

    var totalPrice =
      item1Price * item1Qty -
      item1Discount +
      (item2Price * item2Qty - item2Discount);

    console.log("Purchase event ID: " + trnid);
    var jsonObj = {
      transaction_id: trnid,
      value: totalPrice,
      tax: 4.9,
      shipping: 5.99,
      currency: "USD",
      coupon: "SUMMER_SALE",
      items: [
        {
          item_id: "SKU_12345",
          item_name: "Naveen-Car",
          affiliation: "Google Merchandise Store",
          coupon: "SUMMER_FUN",
          discount: item1Discount,
          index: 0,
          item_brand: "Google",
          item_category: "Apparel",
          item_category2: "Adult",
          item_category3: "Shirts",
          item_category4: "Crew",
          item_category5: "Short sleeve",
          item_list_id: "related_products",
          item_list_name: "Related Products",
          item_variant: "green",
          location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          price: item1Price,
          quantity: 12,
        },
        {
          item_id: "SKU_12346",
          item_name: "Azhar-Bike",
          affiliation: "Google Merchandise Store",
          coupon: "SUMMER_FUN",
          discount: item2Discount,
          index: 1,
          item_brand: "Google",
          item_category: "Apparel",
          item_category2: "Adult",
          item_category3: "Shirts",
          item_category4: "Crew",
          item_category5: "Short sleeve",
          item_list_id: "related_products",
          item_list_name: "Related Products",
          item_variant: "gray",
          location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          price: item2Price,
          promotion_id: "P_12345",
          promotion_name: "Summer Sale",
          quantity: 13,
        },
      ],
    };
    return jsonObj;
  }
  */
  function getDukanyCart() {
    // var trnid = "T_" + Math.random().toString(16).slice(2);
    // var item1Price = randomIntFromInterval(100, 500);
    // var item1Discount = 0.1 * item1Price;
    // var item1Qty = randomIntFromInterval(1, 5);

    // var item2Price = randomIntFromInterval(40, 100);
    // var item2Discount = 0.2 * item2Price;
    // var item2Qty = randomIntFromInterval(1, 3);
    // item1Discount = 0;
    // item2Discount = 0;

    // var totalPrice =
    //   item1Price * item1Qty -
    //   item1Discount +
    //   (item2Price * item2Qty - item2Discount);

    // basketValues?.forEach((element: any) => {
    //   // console.log(element);
    //   // total_basket += element.price + parseInt(deliveryCharges);
    //   // total_basket += element.price;
    //   // total_price += element.price;
    //   console.log(element);
    // });
    var resJson = {
      transaction_id: "",
      total_order_value:
        parseFloat(basketQunatity.price) + parseFloat(deliveryCharges),
      value: basketQunatity.price,
      tax: "N/A",
      is_discount: "No",
      category: "Clothes",
      shipping: deliveryCharges,
      currency: currency,
      affiliation: social_name[socailName as ObjectKey],
      item_brand: "Clothes",
      item_category: "",
      item_category2: "",
      item_category3: "",
      item_category4: "",
      item_category5: "",
      coupon: "N/A",
      items: basketValues,
    };

    console.log(resJson);
    return resJson;
  }

  const [basketQunatity, setBasketQuantity] = useState<any>({});
  const goverRateHandler = (rateValue: any) => {
    // console.log(rateValue);
    setDeliveryChargers(rateValue);
    setShowDeliveryErr(false);
    setBasketQuantity({
      total: basketQunatity.price,
      price: basketQunatity.price,
    });
  };
  const setUserNameHandler = () => {
    var localValues =
      customerLocalInfo !== null && JSON.parse(customerLocalInfo);
    if (
      localValues &&
      localValues !== null &&
      itemDetail?.sPageEnableOTP === "Yes" &&
      localValues.name !== "New" // code for updating the userName in local
    ) {
      setUserName(localValues.name);
    }
  };
  const onSuccessVerified = (customerInfo: any, userPhone: string) => {
    console.log("succes verified method");
    var outletId =
      restoData?.outlets[Object.keys(restoData?.outlets)[0]].outlet_id;
    // restoData?.outlets[Object.keys(restoData?.outlets)[0]].outlet_id;
    console.log(customerInfo);
    // /**
    if (Object.keys(customerInfo).length > 0) {
      // userIpInfo:userIpProxy,
      // cart: reqJson,
      // var reqJson = JSON.stringify(cart);
      var placeOrderValues: IPLACEORDER = {
        order_url: "single_page",
        address_id: "0",
        address_value: address, // user enter addresss, address_value
        userIpInfo: userIpProxy,
        cart: JSON.stringify(basketValues),
        payment_mode: "COD", //
        total_price: basketQunatity?.total,
        mobile_number: userPhone.replace(
          new RegExp(selectedCC + "0", "i"),
          selectedCC
        ),
        customer_name: userName,
        customer_id: customerInfo?.customer_id?.toString(),
        order_instructions: "", // instructions
        delivery_fee: deliveryCharges,
        time: moment(new Date()).format("DD-MM-YYYY hh:mma"),
        selected_area: address, //user selected addess
        ip_address: ipAddressProp,
        order_type: "delivery",
        campaign_date: new Date().getTime().toString(),
        status: "Placed",
        outlet_id: outletId!.toString(),
        recipient_id: "0",
        exchange_rate: "1",
        campaign_type: socailType,
        isDev: 0,
        storeToken: restoToken,
      };

      placeOrder(placeOrderValues);

      setShowMobileModal(false);
    }
  };
  const validationHandler = () => {
    let validate = true;
    if (userName === "") {
      setShowNameErr(true);
      validate = false;
      return;
    }
    if (
      itemDetail?.sPageDeliveryFee !== undefined &&
      itemDetail?.sPageDeliveryFee.length !== 0 &&
      (deliveryCharges === "0" || deliveryCharges === undefined)
    ) {
      setShowDeliveryErr(true);
      validate = false;
      return;
    }
    if (address === "") {
      setShowNumberErr(true);
      validate = false;
      return;
    }
    validate = true;
    return validate;
  };
  const notVerifiedCustomerHandler = () => {
    if (validationHandler()) setShowMobileModal(true);
  };
  const alreadyVerifiedCustomer = () => {
    var localValues =
      customerLocalInfo !== null && JSON.parse(customerLocalInfo);

    if (validationHandler()) {
      placeOrderFunction(localValues);
    }
  };
  const withoutOtpValidation = () => {
    console.log(phoneNumberLenght);
    var removeZero = userPhone.replace(
      new RegExp(selectedCC + "0", "i"),
      selectedCC
    );

    var userPhoneLength = removeZero.length - selectedCC.length;
    if (userName === "") {
      setShowNameErr(true);
      return;
    }
    if (userPhone.length === 0 || userPhoneLength !== phoneNumberLenght) {
      setShowPhoneErr(true);
      return;
    }
    if (
      itemDetail?.sPageDeliveryFee !== undefined &&
      itemDetail?.sPageDeliveryFee.length !== 0 &&
      (deliveryCharges === "0" || deliveryCharges === undefined)
    ) {
      setShowDeliveryErr(true);
      return;
    }
    if (address === "") {
      setShowNumberErr(true);
      return;
    }
    setShowNameErr(false);
    setShowPhoneErr(false);
    setShowNumberErr(false);
    getCustomerInfo(
      userPhone.replace(new RegExp(selectedCC + "0", "i"), selectedCC),
      userName,
      restoToken
    );
  };
  const showFailOrderModal = () => {
    setShowErrorModal(true);
  };
  const hideFailOrderModal = () => {
    setShowErrorModal(false);
  };
  const submitOrder = async () => {
    if (basketValues !== undefined && basketValues.length !== 0) {
      if (itemDetail?.sPageEnableOTP === "No") {
        withoutOtpValidation();
        return;
      }
      if (itemDetail?.sPageEnableOTP === "Yes") {
        if (customerLocalInfo === null) {
          notVerifiedCustomerHandler();
          return;
        }
        if (customerLocalInfo !== null) {
          alreadyVerifiedCustomer();
          return;
        }
      }
    } else {
      setErrAddMore(true);
    }
  };
  useEffect(() => {
    // setDeliveryChargers(restoData!.resto_meta?.DEFAULT_DELIVERY_FEE);
    if (
      itemDetail?.sPageDeliveryFee !== undefined &&
      itemDetail?.sPageDeliveryFee.length !== 0
    ) {
      setDeliveryChargers("0");
    } else {
      setDeliveryChargers(restoData!.resto_meta?.DEFAULT_DELIVERY_FEE);
    }
    if (shopCartStrg !== null) {
      setBasketValues(JSON.parse(shopCartStrg));
      var total_basket = 0;
      var total_price = 0;
      JSON.parse(shopCartStrg)?.forEach((element: any) => {
        // console.log(element);
        // total_basket += element.price + parseInt(deliveryCharges);
        total_basket += element.price;
        total_price += element.price;
      });
      setBasketQuantity({
        total: total_basket,
        price: total_price,
      });
    }
    if (customerLocalInfo !== null && itemDetail?.sPageEnableOTP === "Yes") {
      var localValues = JSON.parse(customerLocalInfo);
      var phone = localValues?.mobile_number;
      // code for updating the userName in local
      if (localValues?.name !== "New") {
        setShowName(false);
      }
      setUserPhone(phone);
    }
  }, []);
  function getEcomSampleCart() {
    var trnid = "T_" + Math.random().toString(16).slice(2);
    var item1Price = 10;
    var item1Discount = 0.1 * item1Price;
    var item1Qty = 1;

    var item2Price = 45;
    var item2Discount = 0.2 * item2Price;
    var item2Qty = 2;

    var totalPrice =
      item1Price * item1Qty -
      item1Discount +
      (item2Price * item2Qty - item2Discount);

    console.log("Purchase event ID: " + trnid);
    var jsonObj = {
      transaction_id: trnid,
      value: totalPrice,
      tax: 4.9,
      shipping: 5.99,
      currency: "USD",
      coupon: "SUMMER_SALE",
      items: [
        {
          item_id: "SKU_12345",
          item_name: "Naveen-Car",
          affiliation: "Google Merchandise Store",
          coupon: "SUMMER_FUN",
          discount: item1Discount,
          index: 0,
          item_brand: "Google",
          item_category: "Apparel",
          item_category2: "Adult",
          item_category3: "Shirts",
          item_category4: "Crew",
          item_category5: "Short sleeve",
          item_list_id: "related_products",
          item_list_name: "Related Products",
          item_variant: "green",
          location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          price: item1Price,
          quantity: 12,
        },
        {
          item_id: "SKU_12346",
          item_name: "Azhar-Bike",
          affiliation: "Google Merchandise Store",
          coupon: "SUMMER_FUN",
          discount: item2Discount,
          index: 1,
          item_brand: "Google",
          item_category: "Apparel",
          item_category2: "Adult",
          item_category3: "Shirts",
          item_category4: "Crew",
          item_category5: "Short sleeve",
          item_list_id: "related_products",
          item_list_name: "Related Products",
          item_variant: "gray",
          location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
          price: item2Price,
          promotion_id: "P_12345",
          promotion_name: "Summer Sale",
          quantity: 13,
        },
      ],
    };
    return jsonObj;
  }

  function getDukanyCart1() {
    var trnid = "T_" + Math.random().toString(16).slice(2);
    var item1Price = 100;
    var item1Discount = 0.1 * item1Price;
    var item1Qty = 5;

    var item2Price = 50;
    var item2Discount = 0.2 * item2Price;
    var item2Qty = (item1Discount = 0);
    item2Discount = 0;

    var totalPrice =
      item1Price * item1Qty -
      item1Discount +
      (item2Price * item2Qty - item2Discount);

    var resJson = {
      transaction_id: trnid,
      value: totalPrice,
      tax: 4.9,
      shipping: 5.99,
      currency: "AED",
      coupon: "N/A",
      items: [
        {
          actual_price: item1Price,
          is_discount: "No",
          is_for_whole_order: "No",
          id: "c845b995-82ed-44f0-a002-467c9c32a138",
          price: item1Price,
          quantity: item1Qty,
          name: "Cleaning Per Hour",
          single_item_name: "",
          single_item_price: item1Price,
          single_options: null,
          multiple_option: [],
          description: "Per Hour Deep Cleaning",
          color_size: null,
        },
        {
          actual_price: item2Price,
          is_discount: "No",
          is_for_whole_order: "No",
          id: "baae7e8f-8006-40bc-99cb-31579c06877c",
          price: item2Price,
          quantity: item2Qty,
          name: "Family Pizza",
          single_item_name: "",
          single_item_price: item2Price,
          single_options: null,
          multiple_option: [],
          description: "2 Medium , 1 Large , 2 Cool drinks",
          color_size: null,
        },
      ],
    };
    console.log("duKaniCart:" + JSON.stringify(resJson));
    return resJson;
  }

  useEffect(() => {
    if (success !== 0) {
      if (success === 1) {
        // gtagGA4("event", "purchase", getDukanyCart);
        // Send a custom event

        var jsonBody = getReqJsonModalGA4WithoutReqData(
          bussinessName!,
          "purchase",
          "N/A",
          window.location.pathname,
          getDukanyCart()
        );
        console.log(jsonBody);
        TrackGoogleAnalyticsEvent("purchase", "purchase", "purchase", jsonBody);

        // console.log(
        //   "function gtagGA4",
        //   //@ts-ignore
        //   gtagGA4("event", "purchase", getDukanyCart)
        // );

        localStorage.removeItem("shopCart");
        // setShopCartStrg(null);
        var finalUrlDomian = null;

        // for redirect to order.dukany domain

        finalUrlDomian = `${redirectDomain}${bussinessName}/thankyou?order=${
          orderPlaced!.order_id
        }&ref=${orderPlaced!.order_ref}&payment=cod`;

        // for redirect to same domain

        // finalUrlDomian = `${redirectDomain}${socailName}/${bussinessName}/thankyou?order=${
        //   orderPlaced!.order_id
        // }&ref=${orderPlaced!.order_ref}&payment=cod`;
        setDisablePlaceOrder(true);
        if (finalUrlDomian !== null) window.location.replace(finalUrlDomian);
        // navigate(
        //   `/thankyou?order=${orderPlaced!.order_id}&ref=${
        //     orderPlaced!.order_ref
        //   }&payment=cod`
        // );
        return;
      }
      if (success === 2) {
        showFailOrderModal();
      }
    }
  }, [success]);

  // 	}
  const onChangePhoneHandler = (phone: string, data: CountryData) => {
    const findIndex = ALL_COUNTRIES_ARRAY.findIndex(
      (item) => item.phone == data.dialCode
    );
    var startsWithZero = phone.startsWith(data.dialCode + "0");
    var removeZero = phone;
    if (startsWithZero) {
      removeZero = phone.replace(new RegExp(selectedCC + "0", "i"), selectedCC);
    }
    var user_phone_length = removeZero.length - data.dialCode.length;
    setPhoneNumberLength(ALL_COUNTRIES_ARRAY[findIndex].phoneLength!);
    if (ALL_COUNTRIES_ARRAY[findIndex].phoneLength === user_phone_length) {
      setPhoneNumberLength(ALL_COUNTRIES_ARRAY[findIndex].phoneLength!);
      setSelectedCC(data.dialCode);
      setUserPhone(phone);
      setShowPhoneErr(false);
    } else {
      setUserPhone(phone);
      setShowPhoneErr(true);
    }
  };
  const updateBasket = (action: string, item: any, index: number) => {
    // console.log(basketValues.cart[index]);
    // console.log(item);
    var new_array: any = [];
    var new_qunatity = 0;
    var new_price = 0;
    var tempBasket: any = {};
    let old = basketValues;
    if (action === "add") {
      new_qunatity = parseInt(item.quantity) + 1;
      new_price = parseInt(old[index].actual_price) * new_qunatity;
      old[index].quantity = new_qunatity;
      old[index].price = new_price;
    } else {
      // if (parseInt(item.quantity)  0) {
      new_qunatity = parseInt(item.quantity) - 1;
      new_price = parseInt(old[index].actual_price) * new_qunatity;
      old[index].quantity = new_qunatity;
      old[index].price = new_price;
      // }
      if (parseInt(item.quantity) === 0) {
        old.splice(index, 1);
      }
      // console.log(old);
    }
    localStorage.setItem("shopCart", JSON.stringify(old));
    localStorage.setItem("shopCartGTM", "ss");
    new_array = localStorage.getItem("shopCart");
    setBasketValues(JSON.parse(new_array));
    var total_basket = 0;
    var total_price = 0;
    old.forEach((element: any, index: number) => {
      if (element.quantity !== 0) {
        total_basket += element.quantity * parseInt(element.actual_price);
        // +
        // parseInt(deliveryCharges);
        total_price += element.quantity * parseInt(element.actual_price);
      }
    });
    tempBasket = {
      total: total_basket,
      price: total_price,
    };
    setBasketQuantity(tempBasket);
  };
  const placeOrderFunction = (customerInfo: any) => {
    setShowMobileModal(false);
    // code for updating the userName in local
    if (customerInfo.name === "New" && itemDetail?.sPageEnableOTP === "Yes") {
      getCustomerInfo(
        // "03122222222",
        userPhone,
        userName,
        restoToken
      ).then((value) => {
        var tempData = {
          ...customerInfo,
          name: userName,
        };
        localStorage.setItem("customerInfo", JSON.stringify(tempData));
      });
    }
    var outletId =
      restoData?.outlets[Object.keys(restoData?.outlets)[0]].outlet_id;

    var placeOrderValues: IPLACEORDER = {
      order_url: "single_page",
      address_id: "0",
      address_value: address, // user enter addresss, address_value
      userIpInfo: userIpProxy,
      cart: JSON.stringify(basketValues),
      payment_mode: "COD", //
      total_price: basketQunatity?.total,
      mobile_number: userPhone.replace(
        new RegExp(selectedCC + "0", "i"),
        selectedCC
      ),
      customer_name: userName,
      customer_id: customerInfo?.id?.toString(),
      order_instructions: "", // instructions
      delivery_fee: deliveryCharges,
      time: moment(new Date()).format("DD-MM-YYYY hh:mma"),
      selected_area: address, //user selected addess
      ip_address: ipAddressProp,
      order_type: "delivery",
      campaign_date: new Date().getTime().toString(),
      status: "Placed",
      outlet_id: outletId!.toString(),
      recipient_id: "0",
      exchange_rate: "1",
      campaign_type: socailType,
      isDev: 0,
      storeToken: restoToken,
    };

    placeOrder(placeOrderValues);
    console.log("called");
  };
  useEffect(() => {
    // var outletId :any;
    //@ts-ignore

    // console.log(firstKeyValue);

    if (Object.keys(customerInfo).length > 0) {
      let parsedData = JSON.parse(shopCartFromStrg!);
      var customerData = {
        ...customerInfo,
        name: userName,
        id: customerInfo.customer_id,
      };
      var singlePageData = {
        customerInfo: customerData,
        ...parsedData,
      };
      localStorage.setItem(
        "singlePage/" + bussinessName,
        JSON.stringify(singlePageData)
      );
      // console.log("called");
      placeOrderFunction(customerData);
    }
  }, [customerInfo]);
  useEffect(() => {
    window.addEventListener("keyboardWillShow", function (event) {
      //@ts-ignore
      const keyboardHeight = event.detail.height;
      const inputField = document.querySelector("input");

      const inputFieldStyle =
        //@ts-ignore
        inputField.currentStyle || window.getComputedStyle(inputField);
      const currentPaddingBottom = parseInt(inputFieldStyle.paddingBottom);
      const newPaddingBottom = keyboardHeight + currentPaddingBottom;
      //@ts-ignore
      inputField.style.paddingBottom = `${newPaddingBottom}px`;
    });

    window.addEventListener("keyboardWillHide", function (event) {
      const inputField = document.querySelector("input");
      //@ts-ignore
      inputField.style.paddingBottom = "50px"; // Adjust this value based on your keyboard height
    });
    // getDukanyCart();
    setUserNameHandler();
  }, []);
  return (
    // <div id="pop_cart_page1" className="overlay">
    <>
      {!disablePlaceOrder ? (
        <>
          <div id="pop_cart_page1" className="shoppingCart" lang={defaultLang}>
            <div className="popup popup_from_bottom popup_slide">
              {shopCartStrg !== null &&
              JSON.parse(shopCartStrg).length !== 0 ? (
                <div>
                  <div className="content">
                    <div className="container">
                      <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <h2 className="fw-normal mb-0 text-black text-center">
                            {/* <svg
                      style={{
                        rotate: defaultLang === "en" ? "180deg" : undefined,
                      }}
                      onClick={() => onPressClose(false)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="21"
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg> */}
                            <div
                            // style={{ fontFamily: "Times New Roman" }}
                            >
                              {" "}
                              {translations.my_basket[defaultLang]}
                            </div>
                            <div></div>
                          </h2>
                        </div>
                        {/* END HEADER HERE */}
                        {showArrayErr && (
                          <div style={{ margin: 10, fontSize: 16 }}>
                            <p>الرجاء تحديد المواد من الصفحة السابقة</p>
                          </div>
                        )}

                        {basketValues.map((item: any, index: number) => {
                          return (
                            <BlockSelectedItem
                              updateBasket={updateBasket}
                              index={index}
                              item={item}
                              currency={currency}
                              defaultLang={defaultLang}
                              bussinessType={
                                restoData?.resto_meta?.BUSSINESS_TYPE
                              }
                            />
                          );
                        })}
                        {errAddMore && (
                          <div
                            style={{
                              marginLeft: 15,
                              marginRight: 15,
                              marginBottom: 5,
                              color: "red",
                              fontSize: 12,
                            }}
                          >
                            {
                              "Your cart is empty. Please add items to continue shopping."
                            }
                          </div>
                        )}
                        <div
                          style={{ margin: errAddMore ? 10 : 0 }}
                          className={`add-new-wrapper ${
                            errAddMore ? "border-error" : ""
                          }`}
                        >
                          <div
                            onClick={() => onPressClose(true)}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <PlusIcon />
                            <text className="add-new-item">
                              {translations.add_more_items[defaultLang]}
                            </text>
                          </div>
                        </div>
                        {/* <!-- content start here --> */}

                        <div className="cart_content customerDetailsFrm">
                          {/* <div className="cart_content_row">
                    <div className="title_acc_cell">
                      Welcome Back ! {customerLocalInfoParsed?.name}
                    </div>
                  </div> */}
                          {showName && (
                            <div className="cart_content_row">
                              <div className="title_acc_cell">
                                {translations.name[defaultLang]}
                              </div>
                              <div className="">
                                <input
                                  onChange={(e) => {
                                    setUserName(e.target.value);
                                    setShowNameErr(false);
                                  }}
                                  value={userName}
                                  type="text"
                                  className={`cart_mobile_input_type ${
                                    showNameErr && "border-error"
                                  }`}
                                />
                              </div>
                            </div>
                          )}

                          {itemDetail?.sPageEnableOTP !== "Yes" && (
                            <div className="cart_content_row">
                              <div className="title_acc_cell">
                                {translations.enter_mobile_number[defaultLang]}
                              </div>
                              <div className="" style={{ direction: "ltr" }}>
                                <ReactPhoneInput
                                  // disabled={itemDetail?.sPageEnableOTP === "Yes"}
                                  enableLongNumbers={true}
                                  inputProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: false,
                                  }}
                                  value={userPhone}
                                  countryCodeEditable={false}
                                  inputStyle={{
                                    minHeight: 38,
                                  }}
                                  country={userCCProp.toLowerCase()}
                                  preferredCountries={["ae,	iq"]}
                                  inputClass={`react-input-style form-control form-control-lg ${
                                    showPhoneErr
                                      ? "border-error"
                                      : "react-input-border"
                                  }`}
                                  enableSearch={true}
                                  onChange={(phone, data: CountryData) => {
                                    onChangePhoneHandler(phone, data);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {/* delivery rate  */}
                          {itemDetail?.sPageDeliveryFee !== undefined &&
                            itemDetail?.sPageDeliveryFee.length !== 0 && (
                              <div className="cart_content_row">
                                <div className="title_acc_cell">
                                  {translations.governer_rate[defaultLang]}
                                </div>
                                <Combobox
                                  containerClassName="combo-box"
                                  style={{ border: 0 }}
                                  className={` ${
                                    showDeliveryErr && "border-error"
                                  }`}
                                  defaultValue="المحافظة"
                                  allowCreate={false}
                                  // textFiled=""
                                  // readOnly={true}

                                  data={itemDetail?.sPageDeliveryFee}
                                  onChange={(text) => {
                                    // @ts-ignore
                                    if (text.value !== undefined) {
                                      goverRateHandler(text.value);
                                    } else {
                                      goverRateHandler("0");
                                    }
                                  }}
                                  textField={
                                    defaultLang === "ar" ? "name_ar" : "name_en"
                                  }
                                  dataKey={"key"}
                                />
                              </div>
                            )}
                          <div className="cart_content_row">
                            <div className="title_acc_cell">
                              {translations.deliver_address[defaultLang]}
                            </div>
                            <div style={{ marginBottom: 28 }}>
                              <textarea
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                  setShowNumberErr(false);
                                }}
                                id="customer_addr"
                                name="customer_addr"
                                className={`form-control form-control-lg ${
                                  showNumberErr && "border-error"
                                }`}
                              ></textarea>
                            </div>
                          </div>
                          <div className="cart_content_row"></div>
                          <div
                          // className="cart_foot"
                          >
                            <div
                              style={{ marginLeft: "4px", marginRight: "4px" }}
                              //  className="cart_content"
                            >
                              <div className="cart_content_row">
                                <div className="cart_content_col_70 flo_left"></div>
                              </div>
                              <div
                                className="cart_content_row"
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {/* <div>{translations.original_price[defaultLang]}</div> */}
                                <div>{translations.sub_total[defaultLang]}</div>
                                <div style={{ paddingLeft: "10%" }}>
                                  <span>
                                    {" "}
                                    {numberWithCommas(basketQunatity?.price)}
                                  </span>
                                  <span> {currency}</span>
                                </div>
                              </div>

                              <div
                                className="cart_content_row"
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  {translations.delivery_fee[defaultLang]}
                                </div>
                                <div style={{ paddingLeft: "10%" }}>
                                  <span>
                                    {numberWithCommas(deliveryCharges)}
                                  </span>
                                  <span> {currency} </span>
                                </div>
                              </div>

                              {/* <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{translations.sub_total[defaultLang]}</div>
                        <div style={{ paddingLeft: "10%" }}>
                          {basketQunatity?.total !== undefined
                            ? basketQunatity?.total
                            : 0}
                          {"   "}
                          {currency}{" "}
                        </div>
                      </div> */}

                              <div
                                className="cart_content_row"
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  {translations.grand_total[defaultLang]}
                                </div>
                                <div
                                  style={{
                                    paddingLeft: "10%",
                                    // fontFamily: "Times New Roman",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span>
                                    {basketQunatity?.total !== undefined
                                      ? numberWithCommas(
                                          basketQunatity?.total +
                                            parseInt(deliveryCharges)
                                        )
                                      : 0}
                                  </span>
                                  {"  "}
                                  <span>{currency}</span>

                                  {/* {"   "} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>

                    <div className="bottomProductTotal">
                      <button
                        style={{
                          position: "relative",
                          zIndex: 1,
                          margin: "10px 20px 0px 18px",
                        }}
                        className="cart_foot_button"
                        onClick={submitOrder}
                        disabled={loading || disablePlaceOrder}
                      >
                        <div className="" style={{ fontWeight: "bold" }}>
                          {!loading
                            ? translations.place_order[defaultLang]
                            : translations.loading[defaultLang]}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyCart
                  onPress={() => onPressClose(true)}
                  defaultLanguage={defaultLang}
                />
              )}
            </div>
            {showErrorModal && (
              <ErrorModal
                headerMessage="Error"
                onPressClose={hideFailOrderModal}
                onBackDropClick={hideFailOrderModal}
                errorMessage="System unable to process request , Contact support"
                buttonMessage="OK"
              />
            )}

            {showMobileModal && (
              <PopConfirmPhone
                useData={restoData}
                customerName={userName}
                defaultLanguage={restoData!.default_lang}
                userCCProp={userCCProp}
                onPressSubmit={(customerData, userPhone) =>
                  onSuccessVerified(customerData, userPhone)
                }
                onPressClose={() => setShowMobileModal(false)}
              />
            )}
            {showMessage && (
              <MessagePage
                customerName={userName}
                onPressClose={() => setShowMessage(false)}
                orderStatus={success}
              />
            )}
          </div>
        </>
      ) : (
        <div id="pop_cart_page1" className="shoppingCart" lang={defaultLang}>
          <div style={{ height: "100vh", zIndex: 1000 }}>
            <div className="popup popup_from_bottom ">
              {/* <div>Hello</div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BasketPage;
