import {
  httpGetWithAuth,
  httpPost,
  httpGet,
} from "../../../setup/axios/axiosUtils";
import { StoreData, Categories, setThemeColor } from "../../pages/HomePage/utils";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { URL_CONSTANTS } from "../../../setup/urlContants";
const useBussinessPage = () => {
  const [useData, setUserData] = useState<StoreData>();
  const { bussinessName, socailName } = useParams();
  const [categories, setCategories] = useState<Categories[]>([]);
  const [userToken, setUserToken] = useState();
  const [loading, setLoading] = useState(false);
  const getUserToken = async (outletName: string) => {
    setLoading(true);
    try {
      const res = await httpGet(`rjs/resto/by/name/${outletName}`,bussinessName!);
      // console.log(res);
      if (res.type === "error") {
        // alert(res.message);
      } else {
        var singlePageData = {
          accessToken: res.data.access_token,
        };
        localStorage.setItem(
          "singlePage/" + outletName,
          JSON.stringify(singlePageData)
        );
        setUserToken(res.data.access_token);
        getBussinessData(res.data.access_token, outletName);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };
  const getBussinessData = async (token: string, outletName: string) => {
    try {
      const res = await httpGetWithAuth(URL_CONSTANTS.get('URL_Bussienss_Info')!,bussinessName!, {}, token);
      // console.log(res);
      if (res.token === "expired") {
        getUserToken(outletName);
      } else {
        var singlePageData = {
          default_color: res.data.default_color,
          default_lang: res.data.default_lang,
          name: res.data.name,
          siteLogo: res.data.site_logo,
          restoId:res.data.id,
          ...JSON.parse(localStorage.getItem("singlePage/" + outletName)!),
        };
        localStorage.setItem(
          "singlePage/" + outletName,
          JSON.stringify(singlePageData)
        );
        setLoading(false);
        setThemeColor(res.data)
        setUserData(res.data);
        // dispatch(
        //   setShopInfo({
        //     id: res.data.id,
        //     name: res.data.name,
        //     payment_method: res.data.payment_method,
        //     accessToekn: token,
        //     default_color: res.data.default_color,
        //     default_lang: res.data.default_lang,
        //     country: res.data.country,
        //     country_code: res.data.country_code,
        //     short_description: res.data.short_description,
        //     country_short_name: res.data.country_short_name,
        //     resto_meta: res.data.resto_meta,
        //     resto_unique_name: res.data.resto_unique_name,
        //     phone: res.data.phone,
        //     place: res.data.place,
        //     rating: res.data.rating,
        //     time_zone: res.data.time_zone,
        //     outletName: outletName,
        //   })
        // );
        // getCategories(token);
      }
    } catch (error) {
      console.log('fffff', error);
      // alert(error.message);
      // setLoading(false);
    }
  };
  const getCategories = async (userToken: string) => {
    // console.log(userToken);
    try {
      const res = await httpPost(bussinessName!,URL_CONSTANTS.get('URL_Bussienss_Categories')!, {}, {}, userToken);
      setCategories(res.data);
      setLoading(false);
    } catch (error: any) {
      // console.log(error.message);
      alert(error.message);
      setLoading(false);
    }
  };
  return {
    getUserToken,
    userToken,
    getBussinessData,
    getCategories,
    useData,
    loading,
    categories,
  };
};
export default useBussinessPage;
