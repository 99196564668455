import React from "react";
import CrossSvg from "./CrossSvg";
import { ObjectKeyLng, StoreData } from "../HomePage/utils";
import { translations } from "../../../assets/meemLabels";
import Seperator from "./Seperator";
import PhoneSvg from "./PhoneSvg";
import WhatsappSvg from "./whatsappSvg";
import FacebookSvg from "./FacebookSvg";
import InstaSvg from "./InstaSvg";
import TiktokSvg from "./TikTokSvg";
import DirectionSvg from "./DirectionSvg";
interface Props {
  onPressClose: () => void;
  useData: StoreData | undefined;
  defaultLang: ObjectKeyLng;
}
// href="//api.whatsapp.com/send?phone=91MOBILE_NUMBER&text=WHATEVER_LINK_OR_TEXT_YOU_WANT_TO_SEND"
const opneWhatsapp = (phoneNumber: string | undefined) => {
  document.location.href = `//api.whatsapp.com/send?phone=${phoneNumber}&text= ?`;
};
const callUsHandler = (phoneNumber: string) => {
  document.location.href = `tel:${phoneNumber}`;
  // dispatch(setCloseAllModal());
};
const redirectHandler = (urlLink: string | undefined) => {
  window.open(urlLink, "_blank");
};

const BussinessInfoPage: React.FC<Props> = (props: Props) => {
  const redirectToBussinesPage = () => {};
  const RenderFiled = ({ name, address, onClick, titleStyle }: any) => {
    return (
      <div className=" bussiness_info_field" onClick={onClick}>
        <div className="bussiness_info_title" style={titleStyle}>
          {name}
        </div>
        <div style={{ marginTop: 4 }}>{address}</div>
      </div>
    );
  };
  const { onPressClose, defaultLang, useData } = props;
  return (
    <div>
      <div
        id="pop_cart_page1"
        className="bussinessInfoClass"
        lang={defaultLang}
      >
        <div className="bussinessInfoClass popup popup_from_bottom popup_right_animation">
          <div>
            <div className="bussiness-page-cross-button" onClick={onPressClose}>
              {/* #F7F7F7 */}
              <CrossSvg height={32} width={32} wrapperBgColor="white" />
            </div>
            <div className="" role="listbox">
              <div
                // className="item active carousel_background_image"
                className="backkground-image-style caurosel_heigth_width"
                style={{
                  backgroundImage: `url(${useData?.cover_image})`,
                }}
              ></div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <RenderFiled
                name={useData?.name[defaultLang]}
                //@ts-ignore
                address={useData?.address["en"]}
                // address={useData?.address[defaultLang]}
              />
              {useData?.social_geo_location !== null && (
                <div
                  className="directionButton"
                  onClick={() => redirectHandler(useData?.social_geo_location)}
                >
                  <div>{translations.view_direction[defaultLang]}</div>{" "}
                  <DirectionSvg />
                </div>
              )}
              <Seperator />
              {useData?.description[defaultLang] !== null && (
                <>
                  <div className=" bussiness_info_field">
                    <div style={{ fontSize: "12px" }}>
                      {useData?.description[defaultLang]}
                      {/* {`شركة تيتانيوم لبيع كافة الملابس الرجالية بأسعار تنافسية و جودة عالية`} */}
                    </div>
                  </div>

                  <Seperator />
                </>
              )}
              {useData?.resto_meta.GEN_BUSINESS_HOURS && (
                <>
                  <RenderFiled
                    titleStyle={{ fontSize: "14px" }}
                    name={translations.workingHours[defaultLang]}
                    // address={"2 ظهرا الى ١٠ مساءًا"}
                    address={useData?.resto_meta.GEN_BUSINESS_HOURS}
                  />
                  <Seperator />
                </>
              )}

              {(useData?.sp_phone_number !== null ||
                useData?.sp_whatsapp_number) && (
                <>
                  <div className=" bussiness_info_field">
                    <div
                      className="bussiness_info_title"
                      style={{ fontSize: 14, marginBottom: 10 }}
                    >
                      {translations.contactDetails[defaultLang]}
                    </div>

                    {null !== useData?.sp_phone_number &&
                      useData?.sp_phone_number !== "" && (
                        <div className="disp_flex">
                          <PhoneSvg />
                          <span
                            style={{
                              marginLeft: 10,
                              marginRight: 10,
                              direction: "ltr",
                            }}
                          >
                            {useData?.sp_phone_number}
                          </span>
                        </div>
                      )}
                    {null !== useData?.sp_whatsapp_number &&
                      useData?.sp_whatsapp_number !== "" && (
                        <div
                          className="disp_flex cusrsorPointer"
                          style={{ marginTop: 10 }}
                          onClick={() =>
                            opneWhatsapp(useData?.sp_whatsapp_number)
                          }
                        >
                          <WhatsappSvg />
                          <span
                            style={{
                              marginLeft: 10,
                              marginRight: 10,
                              direction: "ltr",
                            }}
                          >
                            {useData?.sp_whatsapp_number}
                          </span>
                        </div>
                      )}
                  </div>
                  <Seperator />
                </>
              )}

              <div className="disp_flex" style={{ padding: "30px 20px" }}>
                {useData?.social_fb !== null && (
                  <div
                    className="cusrsorPointer"
                    style={{ marginLeft: 9 }}
                    onClick={() => redirectHandler(useData?.social_fb)}
                  >
                    <FacebookSvg />
                  </div>
                )}
                {useData?.social_insta !== null && (
                  <div
                    className="cusrsorPointer"
                    style={{ marginLeft: 9 }}
                    onClick={() => redirectHandler(useData?.social_insta)}
                  >
                    <InstaSvg />
                  </div>
                )}
                {useData?.social_tiktok !== null && (
                  <div
                    className="cusrsorPointer"
                    style={{ marginLeft: 9 }}
                    onClick={() => redirectHandler(useData?.social_tiktok)}
                  >
                    <TiktokSvg />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BussinessInfoPage;
