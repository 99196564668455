import { httpGet } from "../../../setup/axios/axiosUtils";
import { useState } from "react";
import { SingleItem } from "../../pages/HomePage/utils";
import { URL_CONSTANTS } from "../../../setup/urlContants";
import { useParams } from "react-router-dom";
const useItemDetail = () => {
  const [loadingItem, setLoading] = useState(false);
  const {bussinessName} = useParams();
  const [itemDetail, setItemDetail] = useState<SingleItem>();
  const getItemDetailByid = async (itemId: any) => {
    setLoading(true);
    try {
      
      // const res = await httpGetWithAuth(`recipe?id=${itemId}`, {}, userToken);
      // const res = await httpGet(`business/recipe?id=${itemId}`);
      const res = await httpGet(`${URL_CONSTANTS.get('URL_Bussienss_Recipe')}?id=${itemId}`,bussinessName!);
      // console.log(res);
      setLoading(false);
      if (res.type === "success") {
        setItemDetail(res.data);
      }
    } catch (error: any) {
      // console.log(error.message);
      setLoading(false);
    }
  };
  return {
    getItemDetailByid,
    loadingItem,
    itemDetail,
  };
};
export default useItemDetail;
