import React, { useEffect } from "react";
import HomePage from "./pages/HomePage/index";
import ThankyouPage from "./pages/ThankyouPage/Thankyou";
import TrackOrder from "./pages/TrackOrder/TrackOrder";
//@ts-ignore
import TagManager from "react-gtm-module";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
const App: React.FC = () => {
  const tagManagerArgs = {
    gtmId: "GTM-K8WSRPP",
    events: {
      abdullah: "abullah",
      sendUserInfo: "abdullah testing event",
    },
  };
  useEffect(() => {
    // TagManager.initialize(tagManagerArgs);
    // ReactGA.initialize("G-12KFQWY5HB");
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:socailName/:bussinessName" element={<HomePage />} />
        <Route
          path="/:socailName/:bussinessName/track/order"
          element={<TrackOrder />}
        />
        <Route
          path="/:socailName/:bussinessName/thankyou"
          element={<ThankyouPage />}
        />
      </Routes>
    </BrowserRouter>
    // <HomePage />
  );
};

export default App;
