import React from "react";
import { translations } from "../../../assets/meemLabels";
import { ObjectKeyLng } from "../HomePage/utils";
import Seperator from "./Seperator";
import { numberWithCommas } from "../utilis";
interface Props {
  defaultLang: ObjectKeyLng;
  item: any;
  currency: string;
  bussinessType: string | undefined;
  index: number;
  updateBasket: (action: string, item: any, index: number) => void;
  showSeperator?: boolean;
}
const BlockSelectedItem: React.FC<Props> = (props: Props) => {
  const {
    defaultLang,
    item,
    currency,
    bussinessType,
    updateBasket,
    index,
    showSeperator,
  } = props;
  return (
    <div className="card rounded-3 mb-4">
      <div className="card-body p-4">
        <div className="row d-flex justify-content-between align-items-center  cartDetails">
          <div
            style={{
              float: defaultLang === "en" ? "right" : "left",
              marginTop: "15px",
            }}
            // dir="rtl"
            // col-xs-5
            className=" col-md-5 col-lg-4 col-xl-4 d-flex"
            //"
          >
            <div className="count-number">
              <div
                className="circle-icon-plus "
                onClick={() => updateBasket("sub", item, index)}
              >
                <svg
                  data-v-54a96833=""
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  fill="currentColor"
                  data-spec="icon-plus"
                  className="w-5 h-5"
                  style={{ width: 14, height: 14 }}
                  // style="width: 16px; height: 16px;"
                >
                  <path
                    fill="currentColor"
                    d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                className="prod_counter"
                value={item.quantity}
              />
              <div
                // className="prod_count_min glyphicon glyphicon-minus-sign"
                className="circle-icon-plus "
                onClick={() => updateBasket("add", item, index)}
              >
                <svg
                  data-v-54a96833=""
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  fill="currentColor"
                  data-spec="icon-minus"
                  className="w-5 h-5"
                  style={{ width: 14, height: 14 }}
                >
                  <path
                    fill="currentColor"
                    d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
                  ></path>
                </svg>

                {/* <span className="glyphicon glyphicon-minus-sign"></span> */}
              </div>
            </div>
            <h5 className="mb-0">
              <div className="item-main-price">
                <span>{numberWithCommas(item.price)}</span>
                {"  "}
                <span>{currency}</span>
              </div>{" "}
            </h5>
          </div>
          <div
          // className="col-xs-7 col-md-7 col-lg-8 col-xl-8"
          >
            <div className="cartitemsDetails disp_flex">
              {item?.color_size?.img_url && (
                <span
                  className={`color choose-color basket_color_img`}
                  style={{
                    borderRadius: 5,
                    marginLeft: 10,
                    backgroundImage: `url(${item?.color_size?.img_url})`,
                  }}
                />
              )}
              <div>
                <h3> {item.name}</h3>

                <p
                  className="light_grey_text  max-lines"
                  dangerouslySetInnerHTML={{
                    __html: item?.description,
                  }}
                ></p>
                <span
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{ marginRight: 5 }}
                    className="light_grey_text  max-lines"
                    dangerouslySetInnerHTML={{
                      __html: item?.single_item_name,
                    }}
                  />
                  {item?.single_item_price !== 0 && (
                    <p>
                      {"  "} ({numberWithCommas(item?.single_item_price)}){" "}
                    </p>
                  )}
                </span>
              </div>
            </div>
          </div>
          {/* restoData?.resto_meta?.BUSSINESS_TYPE  */}
          {bussinessType === "ClothsStore" && (
            <p style={{ width: "22em" }}>
              {!item?.color_size?.img_url && (
                <span className="text-muted prdColor">
                  {translations.color[defaultLang]}:{" "}
                  <span
                    className="icon-circle me-2"
                    style={{
                      backgroundColor: item.color_size.color,
                    }}
                  ></span>
                </span>
              )}
              <span className="text-muted prdSize">
                {translations.size[defaultLang]}: {item.color_size.size}
              </span>{" "}
            </p>
          )}
        </div>
        {showSeperator && <Seperator />}
      </div>
    </div>
  );
};

export default BlockSelectedItem;
