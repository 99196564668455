import React, { useEffect, useState } from "react";
import moment from "moment";
import { translations } from "../../../assets/meemLabels";
import useCustomerInfo from "../../hooks/useCustomerInfo";
import { useParams, useSearchParams } from "react-router-dom";
import DoneIconSvg from "../components/DoneIconSvg";
import CrossSvg from "../components/CrossSvg";
import RecordSvg from "../components/RecordSvg";
import LocationSvg from "../components/LocationSvg";
import { ObjectKeyLng } from "../HomePage/utils";
import Footer from "../components/Footer";
const TrackOrder: React.FC = () => {
  const { bussinessName } = useParams();
  const { trackingOrder, trackOrderVal } = useCustomerInfo();
  let [searchParams] = useSearchParams();
  const [defaultColor, setDefaultColor] = useState<string>("");
  const orderId = searchParams.get("order");
  const localData = localStorage.getItem("singlePage/" + bussinessName);
  const [defaultLang, setDefaultLang] = useState<ObjectKeyLng>(
    localData !== null && JSON.parse(localData).default_lang
  );
  const refId = searchParams.get("ref");
  const callUsHandler = () => {
    document.location.href = `tel:${trackOrderVal?.resto?.phone_number}`;
    // dispatch(setCloseAllModal());
  };
  const getValues = () => {
    trackingOrder(orderId!, refId!);
  };
  useEffect(() => {
    getValues();
  }, []);
  useEffect(() => {
    if (trackOrderVal !== undefined) {
      if (trackOrderVal?.resto?.lang === "ar") {
        document.body.dir = "rtl";
      } else {
        document.body.dir = "ltr";
      }
      setDefaultLang(trackOrderVal?.resto?.lang);
      document.documentElement.style.setProperty(
        "--background-color-store",
        trackOrderVal?.resto?.default_color
      );
      setDefaultColor(trackOrderVal?.resto?.default_color);
      document.title = " MS" + "-Track Order";
      // document.title =
      //   JSON.parse(localData)?.default_lang === "en"
      //     ? "Order direct from  " + "  " + JSON.parse(localData)?.name
      //     : "  أطلب مباشرة من" + "  " + JSON.parse(localData)?.name;
      const favicon = document.getElementById("favicon");
      //@ts-ignore
      favicon.href = trackOrderVal?.resto.site_logo;
    }
  }, [trackOrderVal]);
  const toHHMMSS = (secs: any) => {
    const sec_num = parseInt(secs, 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;
    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  };
  return (
    <div
      id="pop_cart_page7"
      className="popup pop_up_maps_main pop_main_menu_bg"
      lang={defaultLang}
    >
      <div className=" popup_cart_full popup_from_bottom  popup_slide">
        <div className="content" id="">
          <div className="cart_head_container">
            <div className="container">
              <div className="header_cart_page">
                <div className="cart_heading">
                  {translations.track_your_order[defaultLang]}
                </div>
                <div className="target_slide glyphicon "></div>
              </div>
            </div>
          </div>
          {/* <!-- content start here --> */}
          <div className="cart_content_alert">
            {translations.order_number[defaultLang]} : #{refId}
          </div>

          <div
            className="cart_content"
            style={{ direction: defaultLang === "en" ? "ltr" : "rtl" }}
          >
            <div className="cart_content_row pad_bot_10">
              <div className="cart_content_col_50">
                {/* <div className="cart_time_bg">18:15</div> */}
                <div className="cart_time_bg">
                  {trackOrderVal?.resto?.estimated_time}
                </div>
              </div>
              <div className="cart_content_col_50">
                <div className="light_grey_text align_rig">
                  {translations.estimated_time[defaultLang]}
                </div>
              </div>
            </div>
            <div className="cart_content_row pad_bot_10 title_acc_cell">
              {translations.order_progress[defaultLang]}
            </div>
            {/*  */}
            {/* {trackOrderVal !== undefined && */}
            {/* // (trackOrderVal?.orderactivities[3]?.status !== "Rejected" ? ( */}
            <>
              <div className="cart_content_row">
                <div className="cart_content_col_15">
                  {trackOrderVal !== undefined &&
                  trackOrderVal?.orderactivities[0] !== undefined ? (
                    <div className="light_grey_text pad_10_up_down">
                      {moment(
                        trackOrderVal?.orderactivities[0]?.updated_at
                      ).format("hh:mm")}
                    </div>
                  ) : (
                    <div className="light_grey_text pad_10_up_down"></div>
                  )}
                </div>

                <div className="cart_content_col_15">
                  {trackOrderVal !== undefined &&
                  trackOrderVal?.orderactivities[0] !== undefined ? (
                    // <div className="glyphicon glyphicon-ok-sign tick_ord_prog"></div>
                    <DoneIconSvg
                      wrapperBgColor={defaultColor}
                      wrapperHeigth={25}
                      wrapperWidth={25}
                      height={25}
                      width={25}
                    />
                  ) : (
                    <RecordSvg height={25} width={25} />
                  )}
                </div>
                <div className="cart_content_col_70_">
                  <div className="light_grey_text pad_10_up_down">
                    {translations.order_placed_txt[defaultLang]}
                  </div>
                </div>
              </div>
              <div className="cart_content_row">
                <div className="cart_content_col_21_5 border_right marg_neg">
                  <br />
                  <br />
                  <br />
                </div>
              </div>

              {/* 2nd */}
              {/*  trackOrderVal?.orderactivities[0]?.status !== "Rejected" &&
              trackOrderVal?.orderactivities[1]?.status !== "Rejected" &&
              trackOrderVal?.orderactivities[2]?.status !== "Rejected" && */}
              <div className="cart_content_row">
                <div className="cart_content_col_15">
                  {trackOrderVal !== undefined &&
                  trackOrderVal?.orderactivities[1] !== undefined ? (
                    <div className="light_grey_text pad_10_up_down">
                      {moment(
                        trackOrderVal?.orderactivities[1]?.updated_at
                      ).format("hh:mm")}
                    </div>
                  ) : (
                    <div className="light_grey_text pad_10_up_down"></div>
                  )}
                </div>

                <div className="cart_content_col_15">
                  {trackOrderVal !== undefined &&
                  trackOrderVal?.orderactivities[1] !== undefined ? (
                    trackOrderVal?.orderactivities[1]?.status !== "Rejected" ? (
                      <DoneIconSvg
                        wrapperBgColor={defaultColor}
                        wrapperHeigth={25}
                        wrapperWidth={25}
                        height={25}
                        width={25}
                      />
                    ) : (
                      <CrossSvg wrapperBgColor={"red"} height={25} width={25} />
                    )
                  ) : (
                    <RecordSvg height={25} width={25} />
                  )}
                </div>
                <div className="cart_content_col_70_">
                  <div className="light_grey_text pad_10_up_down">
                    {trackOrderVal?.orderactivities[1]?.status !== "Rejected"
                      ? translations.in_kitchen_txt[defaultLang]
                      : translations.cancelled[defaultLang]}
                  </div>
                </div>
              </div>
              <div className="cart_content_row">
                <div className="cart_content_col_21_5 border_right marg_neg">
                  <br />
                  <br />
                  <br />
                </div>
              </div>

              {/* 2nd */}

              {/* 3rd */}
              <div className="cart_content_row">
                <div className="cart_content_col_15">
                  {trackOrderVal !== undefined &&
                  trackOrderVal?.orderactivities[2] !== undefined ? (
                    <div className="light_grey_text pad_10_up_down">
                      {moment(
                        trackOrderVal?.orderactivities[2]?.updated_at
                      ).format("hh:mm")}
                    </div>
                  ) : (
                    <div className="light_grey_text pad_10_up_down"></div>
                  )}
                </div>

                <div className="cart_content_col_15">
                  {trackOrderVal !== undefined &&
                  trackOrderVal?.orderactivities[2] !== undefined ? (
                    // <div className="glyphicon glyphicon-ok-sign tick_ord_prog"></div>
                    trackOrderVal?.orderactivities[2]?.status !== "Rejected" ? (
                      <DoneIconSvg
                        wrapperBgColor={defaultColor}
                        wrapperHeigth={25}
                        wrapperWidth={25}
                        height={25}
                        width={25}
                      />
                    ) : (
                      <CrossSvg wrapperBgColor={"red"} height={25} width={25} />
                    )
                  ) : (
                    <RecordSvg height={25} width={25} />
                  )}
                </div>
                <div className="cart_content_col_70_">
                  <div className="light_grey_text pad_10_up_down">
                    {trackOrderVal?.order.order_type !== "pickup"
                      ? translations.on_road_txt[defaultLang]
                      : translations.pickup_txt[defaultLang]}
                  </div>
                </div>
              </div>
              <div className="cart_content_row">
                <div className="cart_content_col_21_5 border_right marg_neg">
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              {/* 3rd */}
              {/* 4th */}
              <div className="cart_content_row">
                <div className="cart_content_col_15">
                  {trackOrderVal !== undefined &&
                  trackOrderVal?.orderactivities[3] !== undefined ? (
                    <div className="light_grey_text pad_10_up_down">
                      {moment(
                        trackOrderVal?.orderactivities[3]?.updated_at
                      ).format("hh:mm")}
                    </div>
                  ) : (
                    <div className="light_grey_text pad_10_up_down"></div>
                  )}
                </div>

                <div className="cart_content_col_15">
                  {trackOrderVal !== undefined &&
                  trackOrderVal?.orderactivities[3] !== undefined ? (
                    trackOrderVal?.orderactivities[3]?.status !== "Rejected" ? (
                      <DoneIconSvg
                        wrapperBgColor={defaultColor}
                        wrapperHeigth={25}
                        wrapperWidth={25}
                        height={25}
                        width={25}
                      />
                    ) : (
                      <CrossSvg wrapperBgColor={"red"} height={25} width={25} />
                    )
                  ) : (
                    <RecordSvg height={25} width={25} />
                  )}
                </div>
                {trackOrderVal?.orderactivities[3] !== undefined &&
                trackOrderVal?.orderactivities[3]?.status !== "Rejected" ? (
                  <div className="cart_content_col_70_">
                    <div className="light_grey_text pad_10_up_down">
                      {/* picked_up_text */}
                      {/* {t("delivered_txt")} */}

                      {trackOrderVal?.order.order_type !== "pickup"
                        ? translations.delivered_txt[defaultLang]
                        : translations.picked_up_text[defaultLang]}
                    </div>
                  </div>
                ) : (
                  <div className="cart_content_col_70_">
                    <div className="light_grey_text pad_10_up_down">
                      {/* picked_up_text */}
                      {/* {t("delivered_txt")} */}

                      {translations.cancelled[defaultLang]}
                    </div>
                  </div>
                )}
              </div>
            </>
            {/* ) */}
            {/* // : (
              //   <div
              //     style={{
              //       display: "flex",
              //       flexDirection: "column",
              //       alignItems: "center",
              //     }}
              //   >
              //     <svg
              //       className="cross-svg-style"
              //       xmlns="http://www.w3.org/2000/svg"
              //       viewBox="0 0 48 48"
              //       width="48px"
              //       height="48px"
              //     >
              //       <path
              //         fill="#FFFFFF"
              //         d="M21.5 4.5H26.501V43.5H21.5z"
              //         transform="rotate(45.001 24 24)"
              //       />
              //       <path
              //         fill="#FFFFFF"
              //         d="M21.5 4.5H26.5V43.501H21.5z"
              //         transform="rotate(135.008 24 24)"
              //       />
              //     </svg>
              //     <div style={{ marginTop: 25 }}>Your Order is rejected</div>
              //   </div>
              // ))} */}
            {/* 4th */}
          </div>

          {/* <!-- content end here --> */}
          <div
            style={{
              backgroundColor: "#fffdf9",
              padding: "10px 10px 15px 25px",
            }}
          >
            <div
              style={{
                width: "10%",
              }}
              className="cart_content_col_15"
            >
              {/* <div className="glyphicon glyphicon-map-marker f_size_24"></div> */}
              <LocationSvg height={16} width={16} />
            </div>
            <div>{trackOrderVal?.resto?.name}</div>
            <div style={{ fontSize: 12 }}>{trackOrderVal?.resto?.address}</div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      {/* <a className="target_slide" href="#pop_cart_page8"> */}
      <button
        onClick={callUsHandler}
        className="cart_button_order cart_foot_button"
        //   onClick={closeAllPops}
      >
        <div className="">{translations.call_us[defaultLang]}</div>
      </button>
      {/* </a> */}
      {/* {showModal && <PopReciverInfo onPressClose={popReciverInfoHide} />} */}
      {trackOrderVal && (
        <Footer
          siteLogo={trackOrderVal?.resto?.site_logo}
          bussinessName={trackOrderVal?.resto?.nameNew[defaultLang]}
        />
      )}
    </div>
  );
};

export default TrackOrder;
