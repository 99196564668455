import React, { useState } from "react";
interface MessagePageProps {
  onPressClose: () => void;
  orderStatus: number;
  customerName: string;
}
const MessagePage: React.FC<MessagePageProps> = (props: MessagePageProps) => {
  const { onPressClose, customerName } = props;
  return (
    <div id="pop_cart_page9" className="pop_up_maps_main">
      <div className="popup_cart_full popup_from_bottom  popup_slide">
        <div className="content" id="">
          <div className="cart_head_container">
            <div className="container">
              <div className="header_cart_page">
                <div className="cart_heading">استلم طلبك</div>
                <div
                  onClick={onPressClose}
                  className="target_slide glyphicon glyphicon-chevron-left"
                ></div>
              </div>
            </div>
          </div>
          <div className="cart_content">
            {
              <div className="">
                <div className="glyphicon glyphicon-ok-sign cart_tick"></div>
                <div className="cart_content_row">
                  <h3 className="cart_thanks_dis">شكرا , {customerName}</h3>
                </div>
                <div className="cart_content_row">
                  <div className="cart_confirmation_alert">تم استلام طلبك</div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagePage;
