import React, { useEffect, useState } from "react";
import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useParams, useNavigate } from "react-router-dom";
import useSendOtp from "../../hooks/useSendOtp";
import PopPageValidateOtp from "../ValidateOtp/PopPageValidateOtp";
import { ObjectKeyLng, StoreData, isNotValidNumber } from "../HomePage/utils";
// import "intl-tel-input/build/css/intlTelInput.css";
import { ALL_COUNTRIES_ARRAY, OtpResData, OtpRespone } from "../utilis";
import Loader from "../Loader";
import BottomButton from "../shared/BottomButton";
import { translations } from "../../../assets/meemLabels";
import BackArrowSvg from "../components/BackArrow";
import SimpleButton from "../shared/SimpleButton";
import Footer from "../components/Footer";
interface ConfirmPhoneProps {
  onPressSubmit: (customerData: any, userPhone: string) => void;
  onPressClose: () => void;
  useData: StoreData | undefined;
  defaultLanguage: string;
  customerName: string;
  userCCProp: string;
}
const PopConfirmPhone: React.FC<ConfirmPhoneProps> = (
  props: ConfirmPhoneProps
) => {
  const {
    onPressClose,
    onPressSubmit,
    userCCProp,
    defaultLanguage,
    useData,
    customerName,
  } = props;
  const { bussinessName } = useParams();
  const shopCartFromStrg = localStorage.getItem("singlePage/" + bussinessName);
  const [selectedCC, setSelectedCC] = useState<string>("");
  const defaultLang: ObjectKeyLng =
    shopCartFromStrg !== null && JSON.parse(shopCartFromStrg).default_lang;
  const accessToken =
    shopCartFromStrg !== null && JSON.parse(shopCartFromStrg).accessToken;
  const shopName =
    shopCartFromStrg !== null && JSON.parse(shopCartFromStrg).name;
  const restoId =
    shopCartFromStrg !== null && JSON.parse(shopCartFromStrg).restoId;
  const [countryCode, setCountryCode] = useState<string>("");
  const [showPhoneErr, setShowPhoneErr] = useState<boolean>(false);
  const userIpInfo = localStorage.getItem("userIpInfo");
  const [userPhone, setUserPhone] = useState<string>("");

  const contryCodeLocal =
    userIpInfo !== null ? JSON.parse(userIpInfo).country_code : "iq";
  const [showValidate, setShowValidate] = useState<boolean>(false);
  // const otpTemp: OtpResData = {
  //   data: {
  //     email: "dddd",
  //     id: 324,
  //     mobile_number: "923105599915",
  //     name: "Muhammad Abdullah Rehan",
  //   },
  //   message: "Customer profile data",
  //   type: "success",
  // };
  const [showError, setShowError] = useState<boolean>(false);
  const { sendOtpHandler, loading, otpData, customerData, otpNotSendReason } =
    useSendOtp();

  const openValidateModel = async () => {
    console.log(userPhone.length < 9 || userPhone.length > 14);
    var objIsNotValidNumber = isNotValidNumber(userPhone, selectedCC);

    if (objIsNotValidNumber.isNotValid) {
      // setShowError(true);
      setShowPhoneErr(true);
    } else {
      // setShowError(false);
      setShowPhoneErr(false);
      await sendOtpHandler(
        objIsNotValidNumber.validatePhone,
        selectedCC,
        restoId,
        shopName,
        defaultLang,
        accessToken
      );
    }
  };
  const closeValidateModal = () => {
    setShowValidate(false);
  };
  const onPressCloseHandler = () => {
    // navigate(`/${bussinessName}/basket`);
    onPressClose();
  };

  useEffect(() => {
    if (otpData !== undefined) {
      setShowValidate(true);
    }
  }, [otpData]);
  return (
    <div
      id="pop_cart_page2 "
      className="popup pop_up_maps_main pop_main_menu_bg"
      lang={defaultLang}
    >
      <div className=" popup_cart_full popup_from_bottom ">
        <div className="content" id="">
          <div className="cart_head_container">
            <div className="container">
              <div className="header_cart_page">
                <div className="cart_heading">&nbsp;</div>
                <div>
                  <BackArrowSvg
                    onPressClose={onPressCloseHandler}
                    isRotate={defaultLang === "en" ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- content start here --> */}
          <div className="cart_content">
            <div className="cart_content_to_marg">
              <div className="cart_content_row">
                <text className="enter-mobilephone" style={{ fontSize: 20 }}>
                  {translations.enter_mobile_number[defaultLang]}
                </text>
                {
                  <div className="light_grey_text" style={{ fontSize: 12 }}>
                    {translations.response_sent_otp[defaultLang]}
                  </div>
                }
              </div>

              <div className="cart_content_row">
                <div className="cart_content_row">
                  <div className="title_acc_cell">
                    {translations.mobile_number[defaultLang]}
                  </div>
                  {/* <div className="" style={{ direction: "ltr" }}>
                        <ReactPhoneInput
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: false,
                          }}
                          // isValid={(value,country)=>handleIsValid()}
                          value={userPhone}
                          countryCodeEditable={false}
                          inputStyle={{
                            minHeight: 38,
                          }}
                          country={userCCProp.toLowerCase()}
                          preferredCountries={["ae,	iq"]}
                          inputClass={`react-input-style form-control form-control-lg ${
                            showPhoneErr ? "border-error" : "react-input-border"
                          }`}
                          enableSearch={true}
                          //  value={countryCode}
                          onChange={(phone, data: CountryData) => {
                            if (phone.charAt(data.dialCode.length) !== "0") {
                              // console.log(phone);
                              setUserPhone(phone);
                            } else {
                              setUserPhone(
                                data.dialCode +
                                  phone.substring(data.dialCode.length + 1)
                              );
                              setSelectedCC(data.dialCode);
                              // console.log(
                              //   data.dialCode +
                              //     phone.substring(data.dialCode.length + 1)
                              // );
                              // phone.replace("0", "");
                            }
                            setShowPhoneErr(false);
                          }}
                        />
                      </div> */}
                  <div className="" style={{ direction: "ltr" }}>
                    <ReactPhoneInput
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: false,
                      }}
                      // isValid={(value,country)=>handleIsValid()}
                      value={userPhone}
                      countryCodeEditable={false}
                      inputStyle={{
                        minHeight: 38,
                      }}
                      country={userCCProp.toLowerCase()}
                      enableLongNumbers={true}
                      preferredCountries={["ae,	iq"]}
                      inputClass={`react-input-style form-control form-control-lg ${
                        showPhoneErr ? "border-error" : "react-input-border"
                      }`}
                      enableSearch={true}
                      //  value={countryCode}
                      onChange={(phone, data: CountryData) => {
                        setUserPhone(phone);
                        setSelectedCC(data.dialCode);

                        setShowPhoneErr(false);
                      }}
                    />
                  </div>
                </div>
                {showPhoneErr && (
                  <div style={{ color: "red", marginTop: 10, fontSize: 10 }}>
                    {" "}
                    <text>Please enter valid phone number</text>{" "}
                  </div>
                )}
                {otpNotSendReason !== "" && !loading && (
                  <div
                    style={{ marginLeft: 20 }}
                    className={
                      otpNotSendReason !== "" ? "show-error-message" : ""
                    }
                  >
                    {/* {ShowErrorMessage("OTP is invalid")} */}
                    <text>{otpNotSendReason}</text>
                  </div>
                )}
                {!showValidate && (
                  <SimpleButton
                    onPressHandler={openValidateModel}
                    titleProp={translations.send_code[defaultLang]}
                    loading={loading}
                    width={"100%"}
                  />
                )}
              </div>
              <div className="cart_content_row"></div>
            </div>
          </div>
          {showValidate && (
            <PopPageValidateOtp
              customerName={customerName}
              selectedCC={selectedCC}
              otpRes={otpData!}
              onPressClose={() => {
                setShowValidate(false);
              }}
              onSuccess={(customerData) =>
                onPressSubmit(customerData, userPhone)
              }
            />
          )}

          {/* <!-- content end here --> */}
        </div>
      </div>

      {/* <Footer
        siteLogo={useData?.site_logo}
        bussinessName={useData?.name[defaultLang]}
      /> */}
    </div>
  );
};

export default PopConfirmPhone;
