import React from "react";
// import { ReactComponent as DoneSvg } from "../../../assets/images/done-icon.svg";
interface BackArrowSvgProps {
  //   height: number | string;
  //   width: number | string;
  onPressClose: () => void;
  isRotate?: boolean;
}
const BackArrowSvg: React.FC<BackArrowSvgProps> = (
  props: BackArrowSvgProps
) => {
  const { onPressClose, isRotate } = props;
  return (
    <svg
      onClick={() => onPressClose()}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="21"
      fill="currentColor"
      className={`bi bi-arrow-right ${isRotate ? "transform_180deg" : ""}`}
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
      />
    </svg>
  );
};

export default BackArrowSvg;
