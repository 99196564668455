import React from "react";

const IconInfo: React.FC = () => {
  return (
    <svg
      id="Group_8485"
      data-name="Group 8485"
      xmlns="http://www.w3.org/2000/svg"
      width="20.39"
      height="20.423"
      viewBox="0 0 16.39 16.423"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_418"
            data-name="Rectangle 418"
            width="16.39"
            height="16.423"
            fill="#b6b6b6"
          />
        </clipPath>
      </defs>
      <g
        id="Group_8484"
        data-name="Group 8484"
        transform="translate(0 0)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Path_9227"
          data-name="Path 9227"
          d="M0,8.878V7.532c.01-.016.029-.03.031-.046.034-.248.063-.5.1-.745A7.317,7.317,0,0,1,.738,4.785,8.253,8.253,0,0,1,2.2,2.6,7.874,7.874,0,0,1,3.6,1.406,7.594,7.594,0,0,1,6.111.282c.3-.073.608-.125.912-.185L7.532,0H8.814a1.484,1.484,0,0,0,.213.063c.314.047.635.063.943.136A8.275,8.275,0,0,1,13.238,1.74a8.388,8.388,0,0,1,1.52,1.536A8.015,8.015,0,0,1,15.95,5.519a8.364,8.364,0,0,1,.424,2.034,6.605,6.605,0,0,1,0,.88c-.02.367-.05.734-.1,1.1a7.258,7.258,0,0,1-.722,2.294,8.137,8.137,0,0,1-1.19,1.777,8.053,8.053,0,0,1-1.478,1.328,7.66,7.66,0,0,1-2.734,1.236,10.35,10.35,0,0,1-1.445.235,7.658,7.658,0,0,1-2.066-.123A8.274,8.274,0,0,1,2.6,14.211,8.153,8.153,0,0,1,1.41,12.817a7.89,7.89,0,0,1-.917-1.774A7.089,7.089,0,0,1,.064,9.254C.053,9.128.022,9,0,8.878m5.73-1.24a1.206,1.206,0,0,0,.141-.031A1.8,1.8,0,0,1,6.819,7.5c.176.026.337.117.355.294a2.668,2.668,0,0,1-.011.811c-.133.6-.313,1.185-.477,1.775a5.194,5.194,0,0,0-.277,1.546,1.2,1.2,0,0,0,.714,1.119,2.151,2.151,0,0,0,1.57.1c.342-.093.666-.25,1.006-.351a.352.352,0,0,0,.272-.34c.011-.09.038-.177.065-.3l-.237.07a1.472,1.472,0,0,1-.96.044c-.132-.05-.258-.124-.269-.273a2.737,2.737,0,0,1,0-.764c.127-.573.3-1.135.457-1.7a5.389,5.389,0,0,0,.3-1.78,1.122,1.122,0,0,0-.644-.984A2.247,2.247,0,0,0,7.1,6.648c-.383.1-.753.248-1.126.384a.251.251,0,0,0-.13.141c-.046.141-.071.289-.111.464m4.431-3.287A1.069,1.069,0,0,0,9.529,3.31a1.307,1.307,0,0,0-1.587.3,1.1,1.1,0,0,0,.1,1.555,1.225,1.225,0,0,0,1.132.321c.462-.092,1.062-.583.99-1.132"
          transform="translate(0 0)"
          fill="#b6b6b6"
        />
      </g>
    </svg>
    // <svg
    //   height="25px"
    //   id="Layer_1"
    //   // style="enable-background:new 0 0 512 512;"
    //   version="1.1"
    //   viewBox="0 0 512 512"
    //   width="25px"
    //   // xml:space="preserve"
    //   xmlns="http://www.w3.org/2000/svg"
    //   // xmlns:xlink="http://www.w3.org/1999/xlink"
    // >
    //   <g>
    //     <path d="M480,253C478.3,129.3,376.7,30.4,253,32S30.4,135.3,32,259c1.7,123.7,103.3,222.6,227,221C382.7,478.3,481.7,376.7,480,253   z M256,111.9c17.7,0,32,14.3,32,32s-14.3,32-32,32c-17.7,0-32-14.3-32-32S238.3,111.9,256,111.9z M300,395h-88v-11h22V224h-22v-12   h66v172h22V395z" />
    //   </g>
    // </svg>
  );
};

export default IconInfo;
