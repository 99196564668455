import React from "react";
import { ReactComponent as DropdownIcon } from "../../../assets/images/down-arrow.svg";
const AccordionItem: React.FC = ({
  faq,
  onToggle,
  active,
  defautlLang,
}: any) => {
  const { question, answer } = faq;
  return (
    <div
      style={{ direction: defautlLang === "en" ? "ltr" : "rtl" }}
      className={`accordion_item ${active ? "active" : ""}`}
    >
      <div
        className="button"
        onClick={onToggle}
        // dangerouslySetInnerHTML={{
        //   __html: question,
        // }}
        // dangerouslySetInnerHTML
        // dangerouslySetInnerHTML={{
        //   __html: question,
        // }}
        // dangerouslySetInnerHTML={{
        //   __html: question,
        // }}
      >
        {question.split("<br />").join("\n")}
        {/* <span
          style={{
            color: "black",
            marginTop: 3,
            marginLeft: 10,
            fontSize: 10,
            float: defautlLang === "en" ? "right" : "left",
          }}
          className="glyphicon glyphicon-menu-down"
        >

        </span> */}
        <span>
          <DropdownIcon
            className="glyphicon-menu-down"
            style={{
              color: "black",
              marginTop: 3,
              marginLeft: 10,
              fontSize: 10,
              float: defautlLang === "en" ? "right" : "left",
            }}
            height={12}
            width={14}
          />
        </span>
        {/* <span className="control">{active ? "—" : "+"} </span> */}
      </div>
      <div className={`answer_wrapper ${active ? "open" : ""}`}>
        <div
          className="answer"
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        >
          {/* {answer.split("<br />").join("\n")} */}
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
