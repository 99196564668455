import React from "react";
interface Props {
  color?: string;
}
const PhoneSvg: React.FC<Props> = (props: Props) => {
  const { color } = props;
  return (
    <svg
      id="Call"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="17.056"
      height="17.5"
      viewBox="0 0 17.056 17.5"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_437"
            data-name="Rectangle 437"
            width="17.056"
            height="17.5"
            fill="#ffa600"
          />
        </clipPath>
      </defs>
      <g id="Group_8509" data-name="Group 8509" clip-path="url(#clip-path)">
        <path
          id="Path_9247"
          data-name="Path 9247"
          d="M126.55,0c.17.034.338.08.51.1a6.652,6.652,0,0,1,1.807.514A7.919,7.919,0,0,1,131,2a7.985,7.985,0,0,1,1.635,2.031,6.632,6.632,0,0,1,.765,1.894,13.3,13.3,0,0,1,.187,1.4c.028.258.032.518.036.778a.693.693,0,0,1-1.365.233,2.484,2.484,0,0,1-.036-.246c-.071-.517-.114-1.041-.221-1.55a9.493,9.493,0,0,0-.4-1.327,5.543,5.543,0,0,0-.921-1.586,5.48,5.48,0,0,0-2.034-1.508,7.594,7.594,0,0,0-1.754-.55c-.424-.079-.853-.132-1.278-.209a.594.594,0,0,1-.538-.582c0-.427.212-.72.547-.755.013,0,.024-.019.035-.029h.889"
          transform="translate(-116.569)"
          fill="#ffa600"
        />
        <path
          id="Path_9248"
          data-name="Path 9248"
          d="M3.006,12.98a1.619,1.619,0,0,1,1.243.545,11.02,11.02,0,0,1,1.561,1.911,6.428,6.428,0,0,1,.828,1.582,1.5,1.5,0,0,1-.516,1.5,3.4,3.4,0,0,0-.79.683,1.041,1.041,0,0,0-.111.971,5.647,5.647,0,0,0,1.032,1.845,9.26,9.26,0,0,0,.78.847,7.515,7.515,0,0,0,2.337,1.464.888.888,0,0,0,1.118-.4,2.5,2.5,0,0,1,.853-.836,1.6,1.6,0,0,1,1-.186,4.166,4.166,0,0,1,1.77.881c.377.263.75.533,1.11.819a4.417,4.417,0,0,1,1.071.983,1.394,1.394,0,0,1,.23,1.023A3.441,3.441,0,0,1,15.2,28.7a3.919,3.919,0,0,1-1.627.845,1.9,1.9,0,0,1-1.081-.077A14.607,14.607,0,0,1,10,28.3c-.652-.367-1.3-.755-1.917-1.171-.553-.37-1.076-.785-1.6-1.191a16.588,16.588,0,0,1-2.053-1.925c-.482-.521-.919-1.085-1.356-1.646A20.108,20.108,0,0,1,.767,18.5a10.709,10.709,0,0,1-.645-1.639A2.836,2.836,0,0,1,.6,14.28a3.676,3.676,0,0,1,2.133-1.3,1.213,1.213,0,0,1,.271,0"
          transform="translate(0 -12.09)"
          fill="#ffa600"
        />
        <path
          id="Path_9249"
          data-name="Path 9249"
          d="M134.942,37.731c0,.371-.01.621,0,.871a.611.611,0,0,1-.611.635c-.563.049-.713-.334-.759-.763a5.252,5.252,0,0,0-.782-2.47,2.921,2.921,0,0,0-1.415-1.067c-.423-.165-.866-.279-1.3-.4-.227-.065-.472-.078-.69-.162a.6.6,0,0,1-.37-.776c.15-.591.457-.577.947-.536a7.349,7.349,0,0,1,1.121.219,4.883,4.883,0,0,1,1.713.823,5.2,5.2,0,0,1,1.912,2.533,4.216,4.216,0,0,1,.236,1.1"
          transform="translate(-120.21 -30.792)"
          fill="#ffa600"
        />
        <path
          id="Path_9250"
          data-name="Path 9250"
          d="M136.271,69.568c-.019.163-.026.3-.052.429a.645.645,0,0,1-.612.515.658.658,0,0,1-.686-.511c-.042-.153-.068-.311-.1-.467a1.479,1.479,0,0,0-1.391-1.251.849.849,0,0,1-.594-.316.722.722,0,0,1,.482-1.053,2.165,2.165,0,0,1,1.671.443,3,3,0,0,1,1.2,1.674,4.921,4.921,0,0,1,.08.535"
          transform="translate(-123.709 -62.339)"
          fill="#ffa600"
        />
      </g>
    </svg>

    // <svg
    //   id="Group_8510"
    //   data-name="Group 8510"
    //   xmlns="http://www.w3.org/2000/svg"
    //   xmlnsXlink="http://www.w3.org/1999/xlink"
    //   width={17.056}
    //   height={17.5}
    //   viewBox="0 0 17.056 17.5"
    //   {...props}
    // >
    //   <defs>
    //     <clipPath id="clip-path">
    //       <rect
    //         id="Rectangle_437"
    //         data-name="Rectangle 437"
    //         width={17.056}
    //         height={17.5}
    //         fill="#ffa600"
    //       />
    //     </clipPath>
    //   </defs>
    //   <g id="Group_8509" data-name="Group 8509" clipPath="url(#clip-path)">
    //     <path
    //       id="Path_9247"
    //       data-name="Path 9247"
    //       d="M126.55,0c.17.034.338.08.51.1a6.652,6.652,0,0,1,1.807.514A7.919,7.919,0,0,1,131,2a7.985,7.985,0,0,1,1.635,2.031,6.632,6.632,0,0,1,.765,1.894,13.3,13.3,0,0,1,.187,1.4c.028.258.032.518.036.778a.693.693,0,0,1-1.365.233,2.484,2.484,0,0,1-.036-.246c-.071-.517-.114-1.041-.221-1.55a9.493,9.493,0,0,0-.4-1.327,5.543,5.543,0,0,0-.921-1.586,5.48,5.48,0,0,0-2.034-1.508,7.594,7.594,0,0,0-1.754-.55c-.424-.079-.853-.132-1.278-.209a.594.594,0,0,1-.538-.582c0-.427.212-.72.547-.755.013,0,.024-.019.035-.029h.889"
    //       transform="translate(-116.569)"
    //       fill="#ffa600"
    //     />
    //     <path
    //       id="Path_9248"
    //       data-name="Path 9248"
    //       d="M3.006,12.98a1.619,1.619,0,0,1,1.243.545,11.02,11.02,0,0,1,1.561,1.911,6.428,6.428,0,0,1,.828,1.582,1.5,1.5,0,0,1-.516,1.5,3.4,3.4,0,0,0-.79.683,1.041,1.041,0,0,0-.111.971,5.647,5.647,0,0,0,1.032,1.845,9.26,9.26,0,0,0,.78.847,7.515,7.515,0,0,0,2.337,1.464.888.888,0,0,0,1.118-.4,2.5,2.5,0,0,1,.853-.836,1.6,1.6,0,0,1,1-.186,4.166,4.166,0,0,1,1.77.881c.377.263.75.533,1.11.819a4.417,4.417,0,0,1,1.071.983,1.394,1.394,0,0,1,.23,1.023A3.441,3.441,0,0,1,15.2,28.7a3.919,3.919,0,0,1-1.627.845,1.9,1.9,0,0,1-1.081-.077A14.607,14.607,0,0,1,10,28.3c-.652-.367-1.3-.755-1.917-1.171-.553-.37-1.076-.785-1.6-1.191a16.588,16.588,0,0,1-2.053-1.925c-.482-.521-.919-1.085-1.356-1.646A20.108,20.108,0,0,1,.767,18.5a10.709,10.709,0,0,1-.645-1.639A2.836,2.836,0,0,1,.6,14.28a3.676,3.676,0,0,1,2.133-1.3,1.213,1.213,0,0,1,.271,0"
    //       transform="translate(0 -12.09)"
    //       fill="#ffa600"
    //     />
    //     <path
    //       id="Path_9249"
    //       data-name="Path 9249"
    //       d="M134.942,37.731c0,.371-.01.621,0,.871a.611.611,0,0,1-.611.635c-.563.049-.713-.334-.759-.763a5.252,5.252,0,0,0-.782-2.47,2.921,2.921,0,0,0-1.415-1.067c-.423-.165-.866-.279-1.3-.4-.227-.065-.472-.078-.69-.162a.6.6,0,0,1-.37-.776c.15-.591.457-.577.947-.536a7.349,7.349,0,0,1,1.121.219,4.883,4.883,0,0,1,1.713.823,5.2,5.2,0,0,1,1.912,2.533,4.216,4.216,0,0,1,.236,1.1"
    //       transform="translate(-120.21 -30.792)"
    //       fill="#ffa600"
    //     />
    //     <path
    //       id="Path_9250"
    //       data-name="Path 9250"
    //       d="M136.271,69.568c-.019.163-.026.3-.052.429a.645.645,0,0,1-.612.515.658.658,0,0,1-.686-.511c-.042-.153-.068-.311-.1-.467a1.479,1.479,0,0,0-1.391-1.251.849.849,0,0,1-.594-.316.722.722,0,0,1,.482-1.053,2.165,2.165,0,0,1,1.671.443,3,3,0,0,1,1.2,1.674,4.921,4.921,0,0,1,.08.535"
    //       transform="translate(-123.709 -62.339)"
    //       fill="#ffa600"
    //     />
    //   </g>
    // </svg>
  );
};

export default PhoneSvg;
