import React from "react";

const DirectionSvg: React.FC = () => {
  return (
    <svg
      id="Group_8530"
      data-name="Group 8530"
      xmlns="http://www.w3.org/2000/svg"
      width="13.489"
      height="13.489"
      viewBox="0 0 13.489 13.489"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_444"
            data-name="Rectangle 444"
            width="13.489"
            height="13.489"
            fill="#ffa600"
          />
        </clipPath>
      </defs>
      <g id="Group_8523" data-name="Group 8523" clip-path="url(#clip-path)">
        <path
          id="Path_9259"
          data-name="Path 9259"
          d="M123.564,0c.159.037.319.07.478.11a2.856,2.856,0,0,1,2.041,2,5.392,5.392,0,0,1,.13.723,3.163,3.163,0,0,1,.023.422,3.556,3.556,0,0,1-.227,1.189,9.118,9.118,0,0,1-.993,1.978c-.285.44-.59.867-.9,1.292-.168.232-.356.449-.537.672a.353.353,0,0,1-.617,0c-.25-.345-.536-.664-.783-1.01-.305-.429-.6-.867-.874-1.315a7.369,7.369,0,0,1-.816-1.758,4.484,4.484,0,0,1-.167-.809,3.32,3.32,0,0,1,.543-2.254A2.579,2.579,0,0,1,122.2.2c.229-.078.471-.12.707-.179.008,0,.014-.013.021-.02Zm-.314,3.944a.98.98,0,1,0-.1-1.95.978.978,0,0,0,.1,1.95"
          transform="translate(-116.611 0.972)"
          fill="#ffa600"
        />
        <path
          id="Path_9260"
          data-name="Path 9260"
          d="M0,407.072c.056-.12.108-.241.171-.357a.306.306,0,0,1,.116-.123c.435-.226.874-.445,1.31-.668q.7-.36,1.4-.726c.061-.032.11-.047.17.014q1.069,1.076,2.142,2.147c.012.012.023.026.037.043l-.181.109H.219L0,407.291v-.219"
          transform="translate(1.059 -395.319)"
          fill="#ffa600"
        />
        <path
          id="Path_9261"
          data-name="Path 9261"
          d="M303,422.275c.013-.017.023-.041.04-.05.36-.186.721-.37,1.081-.556q.839-.434,1.676-.87c.324-.169.648-.337.971-.509.075-.04.106-.041.145.053.2.488.416.972.626,1.457.012.027.027.054.041.08v.175l-.263.219Z"
          transform="translate(-295.302 -410.084)"
          fill="#ffa600"
        />
        <path
          id="Path_9262"
          data-name="Path 9262"
          d="M169.758,273.677c.175-.089.342-.171.506-.258a.109.109,0,0,1,.156.036c.18.219.354.444.547.651a.929.929,0,0,0,1.265.068,3.388,3.388,0,0,0,.479-.522c.258-.328.511-.661.746-1.006.292-.429.576-.865.835-1.314.107-.184.211-.287.428-.247a1.6,1.6,0,0,0,.219,0,.32.32,0,0,1,.3.2c.187.419.369.84.551,1.262.142.33.282.662.422.992a.613.613,0,0,0,.041.1c.042.064.016.091-.044.122q-.757.387-1.511.78l-1.8.933c-.268.139-.535.282-.8.422a.1.1,0,0,1-.144-.014q-1.081-1.087-2.167-2.169a.26.26,0,0,1-.023-.034"
          transform="translate(-164.979 -264.165)"
          fill="#ffa600"
        />
        <path
          id="Path_9263"
          data-name="Path 9263"
          d="M27.867,274.99c.22-.515.429-1.005.639-1.5.143-.333.288-.666.431-1,.1-.23.2-.46.291-.691a.351.351,0,0,1,.335-.253c.15,0,.3,0,.449,0a.117.117,0,0,1,.08.052c.348.565.694,1.132,1.049,1.713l-3.274,1.673"
          transform="translate(-26.197 -264.627)"
          fill="#ffa600"
        />
        <path
          id="Path_9264"
          data-name="Path 9264"
          d="M241.293,121.509a.305.305,0,0,1-.3-.328c0-.2.117-.3.327-.31a.32.32,0,1,1-.023.638"
          transform="translate(-234.649 -117.25)"
          fill="#ffa600"
        />
      </g>
    </svg>
  );
};

export default DirectionSvg;
