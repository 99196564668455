import React from "react";

const CartIconSvg: React.FC = () => {
  return (
    <svg
      id="Group_8534"
      data-name="Group 8534"
      xmlns="http://www.w3.org/2000/svg"
      width="22.85"
      height="23.378"
      viewBox="0 0 18.85 19.378"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_445"
            data-name="Rectangle 445"
            width="18.85"
            height="19.378"
            fill="#b6b6b6"
          />
        </clipPath>
      </defs>
      <g id="Group_8533" data-name="Group 8533" clip-path="url(#clip-path)">
        <path
          id="Path_9271"
          data-name="Path 9271"
          d="M0,0H3.793l.519,5.162H4.54c2.8,0,11.242,0,14.045,0,.138,0,.215.014.265.18l-.855,3.722-1.28,5.129H5.176v1.285H16.863c0,.422,0,.828-.005,1.233,0,.036-.077.07-.119.107a1.837,1.837,0,0,1-.492,2.007,1.857,1.857,0,0,1-2.318.265,1.915,1.915,0,0,1-.852-2.3H7.617a1.9,1.9,0,0,1-.386,1.929,1.92,1.92,0,0,1-1.163.61,1.771,1.771,0,0,1-1.313-.278,1.905,1.905,0,0,1-.842-2.068,2.154,2.154,0,0,0-.039-.413c0-.932.017-1.865-.01-2.8-.015-.517-.11-1.032-.162-1.549-.035-.351-.054-.7-.09-1.054-.052-.509-.116-1.018-.168-1.527-.036-.351-.054-.7-.09-1.054-.052-.509-.116-1.018-.168-1.527-.036-.351-.054-.7-.09-1.054C3.044,5.5,2.98,4.992,2.928,4.482c-.036-.351-.054-.7-.09-1.054C2.786,2.919,2.723,2.411,2.67,1.9c-.02-.2-.027-.393-.042-.611H0Z"
          fill="#b6b6b6"
        />
      </g>
    </svg>
    // <svg
    //   enable-background="new 0 0 64 64"
    //   height="30px"
    //   id="Layer_1"
    //   version="1.1"
    //   viewBox="0 0 64 64"
    //   width="30px"
    //   // xml:space="preserve"
    //   xmlns="http://www.w3.org/2000/svg"
    //   // xmlns:xlink="http://www.w3.org/1999/xlink"
    // >
    //   <g>
    //     <path d="M23.734,28.125c1.104,0,2-0.896,2-2v-7.8c0-3.487,2.837-6.325,6.324-6.325c3.487,0,6.325,2.838,6.325,6.325v7.8   c0,1.104,0.895,2,2,2c1.104,0,2-0.896,2-2v-7.8C42.384,12.632,37.752,8,32.058,8c-5.692,0-10.324,4.632-10.324,10.325v7.8   C21.734,27.229,22.63,28.125,23.734,28.125z" />
    //     <path d="M55,23.631H44.384v2.494c0,2.206-1.794,4-4,4s-4-1.794-4-4v-2.494h-8.649v2.494c0,2.206-1.794,4-4,4s-4-1.794-4-4v-2.494H9   c-0.552,0-0.893,0.435-0.762,0.971l6.998,28.497C15.658,54.701,17.344,56,19,56h26c1.658,0,3.342-1.299,3.766-2.901l6.996-28.497   C55.893,24.065,55.553,23.631,55,23.631z" />
    //   </g>
    // </svg>
  );
};

export default CartIconSvg;
