//import { AxiosRequestConfig } from "axios";
import { AxiosRequestConfig } from "axios";
import { baseURL, instance } from "./axios";
import { GA4EventsNames, HttpCodes, ReqSuccessMessage, TrackGoogleAnalyticsEvent, getReqJsonModalGA4 } from "../../app/pages/utilis";
// TODO Should use the token in the userData context
export const httpGet = async (
  requestPath: string,
  bussinessName:string,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.get(requestPath, {
    ...config,
  });
  if (axiosResponse.status === HttpCodes.success && axiosResponse.data.type === ReqSuccessMessage){
    var jsonBody = getReqJsonModalGA4(bussinessName,'success','N/A',baseURL+requestPath,{success:'success'})
    TrackGoogleAnalyticsEvent('Api Call',GA4EventsNames.apiSuccess,GA4EventsNames.apiSuccess,jsonBody);
  }
  return axiosResponse.data;
};
export const httpGetWithAuth = async (
  requestPath: string,
  bussinessName:string,
  config?: AxiosRequestConfig,
  userToken?: string
): Promise<any> => {

// 6WBcUiRMOxrvxau8UAbP7T5aTL5MaEE6ng7Lux7Y2S7DTy6sHtHMp9pOPHEW2

var  axiosResponse = null;
// try { 
 
   axiosResponse = await instance.get(requestPath, {
    headers: {
      Authorization: "Bearer " + userToken,
    },
    ...config,
  });

  if (axiosResponse !== null && axiosResponse.status === HttpCodes.success && axiosResponse.data.type === ReqSuccessMessage){
    var jsonBody = getReqJsonModalGA4(bussinessName,'success','N/A',baseURL+requestPath,{success:'success'})
    TrackGoogleAnalyticsEvent('Api Call',GA4EventsNames.apiSuccess,requestPath,jsonBody);
  }
  else{
    var jsonBody = getReqJsonModalGA4(bussinessName,'Fail',axiosResponse.data.response,baseURL+requestPath,axiosResponse.data.data)
    TrackGoogleAnalyticsEvent('Api Call',GA4EventsNames.apiFailed,GA4EventsNames.apiFailed,jsonBody);
  }
  return axiosResponse.data;

//  console.log(axiosResponse)

 
};
export const httpPost = async (
  bussinessName:string,
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig,
  userToken?: string,
): Promise<any> => {
  var axiosResponse = null;
// try {
  axiosResponse = await instance.post(requestPath, data, {
    headers: {
      Authorization: "Bearer " + userToken,
    },
    ...config,
  });
  if (axiosResponse.status === HttpCodes.success && axiosResponse.data.type === ReqSuccessMessage){
    var jsonBody = getReqJsonModalGA4(bussinessName,'success','N/A',baseURL+requestPath,{success:'success'})
    TrackGoogleAnalyticsEvent('Api Call',GA4EventsNames.apiSuccess,GA4EventsNames.apiSuccess,jsonBody);
  }
  else{
    var jsonBody = getReqJsonModalGA4(bussinessName,'Fail',axiosResponse.data.response,baseURL+requestPath,axiosResponse.data.data)
    TrackGoogleAnalyticsEvent('Api Call',GA4EventsNames.apiFailed,GA4EventsNames.apiFailed,jsonBody);
  }
  
  return axiosResponse.data;
  


   
};

export const httpPut = async (
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.put(requestPath, data, {
    headers: {
      Authorization: "Bearer null",
    },
    ...config,
  });
  return axiosResponse.data;
};

export const httpDelete = async (
  requestPath: string,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.delete(requestPath, {
    headers: {
      Authorization: "Bearer null",
    },
    ...config,
  });
  return axiosResponse.data;
};
