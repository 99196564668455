import React, { useEffect, useState } from "react";
import { translations } from "../../../assets/meemLabels";
import DoneIconSvg from "../components/DoneIconSvg";
import useCustomerInfo from "../../hooks/useCustomerInfo";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ObjectKeyLng } from "../HomePage/utils";
import Footer from "../components/Footer";
const ThankyouPage: React.FC = () => {
  const navigate = useNavigate();
  const { bussinessName, socailName } = useParams();
  let [searchParams] = useSearchParams();
  const { trackingOrder, trackOrderVal } = useCustomerInfo();
  const orderId = searchParams.get("order");
  const localData = localStorage.getItem("singlePage/" + bussinessName);
  const customerInfo = localStorage.getItem("customerInfo");
  const refId = searchParams.get("ref");
  const [defaultLang, setDefaultLang] = useState<ObjectKeyLng>(
    localData !== null && JSON.parse(localData).default_lang
  );
  const [userName, setUserName] = useState<string>("");
  const popPageOrderShow = () => {
    navigate(
      `/${socailName}/${bussinessName}/track/order?order=${orderId}&ref=${refId}`
    );
  };
  const getValues = () => {
    trackingOrder(orderId!, refId!);
  };
  useEffect(() => {
    getValues();
  }, []);

  useEffect(() => {
    if (trackOrderVal !== undefined) {
      setUserName(trackOrderVal.order?.customer_name);
      document.documentElement.style.setProperty(
        "--background-color-store",
        trackOrderVal?.resto?.default_color
      );
      // JSON.parse(localData)?.default_lang === "en"
      //   ? "Order direct from  " + "  " + JSON.parse(localData)?.name
      //   : "  أطلب مباشرة من" + "  " + JSON.parse(localData)?.name;
      const favicon = document.getElementById("favicon");
      setDefaultLang(trackOrderVal?.resto?.lang);
      //@ts-ignore
      favicon.href = trackOrderVal?.resto?.site_logo;
    }
  }, [trackOrderVal]);

  return (
    <>
      <div
        id="pop_cart_page9"
        className="popup pop_up_maps_main"
        lang={defaultLang}
      >
        <div className=" popup_cart_full popup_from_bottom  popup_slide">
          <div className="content" id="">
            <div className="cart_head_container">
              <div className="container">
                <div className="header_cart_page">
                  <div className="cart_heading">
                    {translations.order_placed[defaultLang]}
                  </div>
                  <div
                    // onClick={() => navigate(-1)}
                    className="target_slide glyphicon "
                  ></div>
                </div>
              </div>
            </div>
            {/* <!-- content start here --> */}
            <div className="cart_content">
              <div className="">
                {/* <div className="glyphicon glyphicon-ok-sign cart_tick"></div> */}
                <div
                  style={{
                    marginLeft: "30%",
                    marginTop: "10%",
                    marginRight: "31%",
                  }}
                >
                  <DoneIconSvg
                    wrapperBgColor="#4AAE50"
                    height={130}
                    width={120}
                    wrapperHeigth={130}
                    wrapperWidth={130}
                  />
                </div>
                <div className="cart_content_row">
                  <h3 className="cart_thanks_dis">
                    {/* {t("thanks")}, {userName} */}
                    {translations.thanks[defaultLang]} {"   "} {userName}
                  </h3>
                </div>
                <div className="cart_content_row">
                  <div className="cart_confirmation_alert">
                    {translations.order_placed_txt[defaultLang]}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content end here --> */}

            <div className="cart_foot">
              {/* <a className="target_slide" href="#pop_cart_page10"> */}
              <div className="share-button-thankyou">
                <button className="cart_foot_button_rev">
                  <div className="">{translations.share[defaultLang]}</div>
                </button>
                <div
                  className="light_grey_text res_font_size1 text_center"
                  style={{ marginBottom: 22 }}
                >
                  {translations.share_with_friends[defaultLang]}
                </div>
              </div>
              <button className="cart_foot_button" onClick={popPageOrderShow}>
                <div className="">
                  {translations.track_your_order[defaultLang]}
                </div>
              </button>
              {/* </a> */}
            </div>
          </div>
        </div>
        {trackOrderVal && (
          <Footer
            siteLogo={trackOrderVal?.resto?.site_logo}
            bussinessName={trackOrderVal?.resto?.nameNew[defaultLang]}
          />
        )}
      </div>

      {/* <div className="landscape_logo">
        <img src={siteLogo} className="landscape_logo_img" alt="" />
        <div className="landscape_logo_text">{restoName}</div>
      </div> */}
    </>
  );
};

export default ThankyouPage;
