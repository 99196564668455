export const translations = {
  choose_atleast :{
    en:"Choose at least",
    ar:'اختر على الأقل',
  },
  required_txt:{
    en:"REQUIRED",
    ar:"مطلوب",
  },
  view_direction:{
    en:"VIEW DIRECTION",
    ar:'إظهار الاتجاه',
  },
  cart_empty_txt_desc: {en:"But it's waiting to be filled with your favorite items",ar:"لكنها تنتظر ملؤها بالعناصر المفضلة لديك"},
  cart_empty_txt_1: {en:"Your cart is empty.",ar:"عربة التسوق الخاصة بك فارغة"},
  start_shopping: { en: "START SHOPPING", ar: "عاود التسوق" },

  pleaseMakeSelection: {
    en: "(Please make selection)",
    ar: "(يرجى الاختيار)",
  },
  workingHours: {
    en: "Working hours",
    ar: "ساعات العمل",
  },
  welcome_back: {
    en: "",
    ar: "مرحبًا بعودتك",
  },
  contactDetails: {
    en: "Contact details",
    ar: "إتصل بنا",
  },
  shoppingBag: {
    en: "Shopping Bag",
    ar: "سلة التسوق",
  },
  basket: {
    en: "Basket",
    ar: "سلتي ",
  },
  addMore: {
    en: "Add More",
    ar: "أضف مادة جديدة",
  },
  openBasket: {
    en: "Open Basket",
    ar: "تأكيد و تثبيت الطلب",
  },
  add: { en: "Add", ar: "أضف" },
  add_new_address: { en: "Add new address", ar: " اضف عنوان جديد" },
  additional_instruction: {
    en: "Additional Instructions",
    ar: "ملاحظات اضافية",
  },
  additional_instruction_txt: {
    en: "Add additional information about your request.",
    ar: "اضف معلومات اضافية عن طلبك.",
  },
  address: { en: "Address", ar: "العنوان " },
  address_extras: {
    en: "Apt, villa,or floor number",
    ar: "شقة، بيت، او رقم الشقة",
  },
  address_label: { en: "Address Label", ar: "العناوين" },
  address_new_address_title: { en: "Add new address", ar: "اضف عنوان جديد" },
  back_to_menu: { en: "Back to menu", ar: "العودة إلى القائمة" },
  call_us: { en: "Call us", ar: "اتصل بنا" },
  cancelled: { en: "Your order cancelled", ar: "الغي الطلب" },
  card: { en: "QiCard/Master Card", ar: " بطاقة كي كارد / ماستر كارد  " },
  change_location: { en: "Change Location", ar: "تغيير الموقع" },
  change_time_location: { en: "Change", ar: "غير" },
  choose_color: { en: "Choose Color", ar: "اختر اللون" },
  color: { en: "Color", ar: " اللون" },
  add_item: { en: "Add Item", ar: "أضف للسلة" },
  add_more_items: { en: "Add More Items", ar: "أضف مادة جديدة" },
  choose_delivery: { en: "Delivery to", ar: "التوصيل الى" },
  faqs: { en: "FAQ'S", ar: "الأسئلة الشائعة" },
  choose_item_location: {
    en: "Select Delivery Location",
    ar: "اختر موقع التوصيل",
  },
  choose_location: { en: "Choose Address", ar: " اختر عنوان" },
  choose_receiver_first: {
    en: "Choose receiver first",
    ar: "اختر المستلم أولا",
  },
  choose_size: { en: "Choose Size", ar: "اختر القياس" },
  size: { en: "Size", ar: " القياس" },
  choose_time_slots: { en: "Choose time slot", ar: "اختر فترة زمنية" },
  cod: { en: "Cash on Delivery", ar: "نقد عند التسليم " },
  confirm_delivery_date_time: {
    en: "Confirm Delivery Date & Time",
    ar: "أكد تاريخ ووقت التوصيل",
  },
  confirm_location_button: { en: "Confirm", ar: "اكد الموقع" },
  confirm_pickup_date_time: {
    en: "Confirm Pickup Date & Time",
    ar: "أكد تاريخ ووقت التسليم",
  },
  governer_rate: {
    en: "Delivery address / Provinces",
    ar: "عنوان التوصيل / المحافظة",
  },
  contactless_dinning: { en: "Contactless Dining", ar: "داخل المطعم" },
  cop: { en: "Cash on Pickup", ar: "نقد عند الاستلام" },
  create_button: { en: "Create", ar: "انشئ" },
  create_new_acount: { en: "Create a new account", ar: "انشئ حساب جديد" },
  create_new_acount_txt: {
    en: "Create your account to be able to easily order from ",
    ar: "                    انشئ حسابك لتتمكن بسهولة من الطلب من ",
  },
  default_ccy: { en: "AED", ar: ". ع" },
  delivered: { en: "Delivered", ar: "التسليم " },
  delivered_txt: { en: "Your order has been delivered", ar: "تم تسليم طلبك" },
  delivery: { en: "Delivery", ar: "توصيل" },
  delivery_fee: { en: "Delivery Fee", ar: "كلفة التوصيل" },
  delivery_instruction: { en: "Delivery Instructions", ar: "تعليمات التوصيل" },
  delivery_instruction_placeholder: { en: "Example", ar: "مثال " },
  delivery_time: { en: "ASAP", ar: "اقرب وقت" },
  delivery_timing: { en: "Delivery Time", ar: "وقت التوصيل" },
  dining: { en: "Dine-in", ar: "داخل المطعم" },
  Discount: { en: "Discount", ar: "تخفيض" },
  discount_label: { en: "Discount", ar: "خصم" },
  enter_greeting_message: { en: "Enter Greeting Message", ar: "ادخل الرسالة" },
  enter_mobile_number: {
    en: "Enter your mobile number",
    ar: "ادخل رقم موبايلك",
  },
  whatsapp_number: {
    en: "Whatsapp Number",
    ar: "رقم الواتس اب",
  },
  enter_mobile_number_txt: {
    en: "OTP will be sent on your WhatsApp , Kindly check .",
    ar: "سوف نرسل لك رسالة وتساب تحتوي على كود التحقق",
  },
  estimated_time: { en: "Est. delivery Time", ar: "الوقت المتوقع لوصول الطلب" },
  estimated_time_homepage: { en: "Est. delivery time", ar: "وقت التوصيل" },
  grand_total: { en: "Total", ar: "المجموع الكلي" },
  loading: { en: "Loading...", ar: "...جاري التحميل" },
  request: { en: "Request", ar: "اطلب" },

  home: { en: "Home", ar: "البيت   " },
  in_kitchen: { en: "Prepare the order", ar: "تحضير الطلب" },
  in_kitchen_txt: {
    en: "Preparing the order now",
    ar: "جاري تحضير الطلب الآن",
  },
  is_customizable: { en: "Customizable", ar: "يحتوي اختيارات" },
  master_card: { en: "Credit Card", ar: "ماستر كارد" },
  menu_categories: { en: "Menu Categories", ar: "فئات قائمة الطعام" },
  message: { en: "Message (optional)", ar: "الرسالة" },
  min_basket_value: { en: "a minimum order value", ar: "اقل قيمة للطلب" },
  minutes: { en: "minutes", ar: "دقيقة" },
  mobile_number: { en: "Mobile Number", ar: "رقم الموبايل" },
  my_basket: { en: "My Order", ar: "طلبي" },
  name: { en: "Name", ar: "الاسم" },
  new: { en: "Placed", ar: "طلب جديد" },
  new_receiver: { en: "New Receiver", ar: "مستلم جديد" },
  no_greeting_message: { en: "No Greeting Message", ar: "لا توجد رسالة" },
  no_receiver: { en: "No Receiver", ar: "لا يوجد مستلم" },
  office: { en: "Office", ar: "  العمل " },
  on_road: { en: "On the Way", ar: " في الطريق" },
  on_road_txt: {
    en: "Your order is now on the way",
    ar: "طلبك الان في الطريق",
  },
  order_number: { en: "Order number", ar: "رقم الطلب" },
  order_placed: { en: "Order Placed", ar: "استلم طلبك" },
  order_placed_txt: { en: "Your order is placed.", ar: "تم استلام طلبك" },
  order_progress: { en: "Order Progress", ar: "حالة الطلب" },
  order_review: { en: "Order Review", ar: "تاكد من طلبك" },
  original_price: { en: "Original Price", ar: "السعر الأصلي" },
  others: { en: "Others", ar: "   اخرى " },
  otp_validated: { en: "OTP is confirmed", ar: "تم تأكيد OTP" },
  out_of_location: {
    en: "The delivery location is still outside our delivery area. However, you can try a different location or select the pick up option to get your order at the port.",
    ar: "موقع التسليم لا يزال خارج منطقة التوصيل لدينا. ومع ذلك ، يمكنك تجربة موقع مختلف أو تحديد خيار الاستلام للحصول على طلبك في المنفذ.",
  },
  out_of_location_heading: {
    en: "We haven't gotten there yet",
    ar: "لم نصل إلى هناك بعد",
  },
  payment_methods: { en: "Payment Methods", ar: "طريقة الدفع" },
  payment_summary: { en: "Payment Summary", ar: "ملخص الدفع" },
  picking_up_from: { en: "Picking up from", ar: "الاستلام من" },
  pickup: { en: "Pickup", ar: "استلام" },
  pickup_time: { en: "Pickup Time", ar: "وقت الاستلام" },
  pickup_txt: { en: "Pickup your order", ar: "Pickup your order" },
  picked_up_text: { en: "Your order has been picked up", ar: "تم استلام طلبك" },
  place_order: { en: "Place Order", ar: "ارسل طلبك" },
  please_choose_a_datetime: {
    en: "Place Order",
    ar: "الرجاء اختيار تاريخ / وقت التوصيل لطلبك",
  },
  please_choose_a_datetime_pickup: {
    en: "Please Choose a date/time for your pickup",
    ar: "الرجاء اختيار تاريخ / وقت استلام الطلب",
  },
  receiver_information: { en: "Receiver Information", ar: "معلومات المستلم" },
  receiver_name: { en: "Receiver Name", ar: "اسم المستلم" },
  receiver_phone: { en: "Receiver Phone", ar: "موبايل المستلم" },
  refine_location: { en: "Refine Location", ar: "صقل الموقع" },
  response_sent_otp: {
    en: "OTP Sent on your WhatsApp , Kindly check .",
    ar: "لقد ارسلنا لك رسالة وتساب تحتوي على كود التحقق",
  },
  save_address: { en: "Save Address", ar: "خزن العنوان" },
  save_receiver: { en: "Save Receiver", ar: "احفظ المستلم" },
  search_box_text: { en: "Find your location", ar: "ابحث عن موقعك" },
  seconds: { en: "seconds", ar: "انية " },
  select_address: { en: "Select Address", ar: "اختر عنوان" },
  select_address_txt: {
    en: "The location is needed to start ordering",
    ar: "العنوان ضروري لايصال الطلب",
  },
  select_delivery_date_time: {
    en: "Select Delivery Date & Time",
    ar: "حدد تاريخ ووقت توصيل الطلب",
  },
  select_location: { en: "Select your location", ar: "حدد موقعك" },
  select_map_location: {
    en: "Select the delivery location",
    ar: "حدد موقع التوصيل",
  },
  select_map_location_txt: {
    en: "Select the exact location to help our driver deliver your order quickly",
    ar: "حدد الموقع الدقيق لمساعدة سائقنا على تسليم طلبك بسرعة",
  },
  deliver_address: {
    en: "Delivery Address",
    ar: "عنوان التوصيل",
  },
  select_pickup_date_time: {
    en: "Select Pickup Date & Time",
    ar: "حدد تاريخ ووقت استلام الطلب",
  },
  select_time: { en: "Select Time", ar: "حدد الوقت" },
  send_code: { en: "Send Code", ar: "ارسل الكود" },
  send_verification_message: {
    en: "We have sent the verification code to",
    ar: "لقد قمنا بارسال كود التحقق الى",
  },
  share: { en: "Share", ar: "شارك " },
  share_with_friends: {
    en: "Share it with your friends",
    ar: "شاركه مع اصحابك",
  },
  sub_total: { en: "Sub Total", ar: "قيمة الطلب" },
  table: { en: "Table", ar: "الطاولة" },
  table_no: { en: "Table No", ar: "رقم الطاولة" },
  terms_condition: {
    en: "By placing order you agreed to term&Conditions",
    ar: "بتاكيدك للطلب تقر بموافقتك على الشروط و الاحكام",
  },
  text_field_place_holder: { en: "Enter your name", ar: "ادخل اسمك" },
  thanks: { en: "Thanks", ar: "شكرا " },
  to_my_cart: { en: " to my cart", ar: "الى سلتي" },
  today: { en: "Today", ar: "اليوم" },
  track_order: { en: "Track Order", ar: "تتبع طلبك" },
  track_your_order: { en: "Track your order", ar: "تتبع الطلب" },
  verifitcation_code_error: {
    en: "Did not receive code",
    ar: "لم استلم كود التحقق",
  },
  verify_number: {
    en: "Check your WhatsApp.",
    ar: "تحقق من الواتس اب الخاص بك",
  },
  your_order: { en: "Your Order", ar: "طلبك" },
  "order direct from": { en: "Your order is now on the way", ar: "ملخص الدفع" },
};
