import { httpPost, httpGetWithAuth, httpGet } from "../../../setup/axios/axiosUtils";
import { useState } from "react";
import { CustomerInfo, IPLACEORDER, IProxyIp, OrderResponse } from "../../pages/HomePage/utils";
import { useParams } from "react-router-dom";
import { URL_CONSTANTS } from "../../../setup/urlContants";
import { GA4EventsNames, TrackGoogleAnalyticsEvent, getReqJsonModalGA4 } from "../../pages/utilis";
const useCustomerInfo = () => {
  var exsitingUser: boolean = false;
  const {bussinessName} = useParams();
  const [success, setSuccess] = useState<number>(0);
  const [orderPlaced, setOrderPlaced] = useState<OrderResponse>();
  const [trackOrderVal, setTrackOrderVal] = useState<any>();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>(
    {} as CustomerInfo
  );
  const [loading, setLoading] = useState(false);
  const getCustomerInfo = async (
    mobile_number: string,
    name: string,
    token: string
  ) => {
    setLoading(true);
    try {
      const res = await httpPost(
        bussinessName!,
        URL_CONSTANTS.get('URL_GET_CREATE_CUSTOMER')!,
        {
          mobile_number,
          name,
        },
        {},
        token
      );
      // console.log(res);
      if (res.type === "success") {
        setCustomerInfo(res.data);
        if (res.data.new_user === true) {
          exsitingUser = true;
        } else {
          exsitingUser = false;
        }
      }
    } catch (error: any) {
      // console.log(error.message);
      // alert(error.message);
      setLoading(false);
    }
    return exsitingUser;
  };
  const placeOrder = async (
    placeOrderValues:IPLACEORDER
  ) => {
    // var reqJson = JSON.stringify(cart);
    setSuccess(0);
    setLoading(true);
    try {
      const res = await httpPost(
        bussinessName!,
        `rjs/confirmed/order?`,
        {
          ...placeOrderValues,
          
        },
        {},
        placeOrderValues.storeToken
      );
      if (res.type === "success") {
        setOrderPlaced(res.data);
        setSuccess(1);
      }else{
        setSuccess(2);
      }
      setLoading(false);
    } catch (error:any) {
      setSuccess(2);
      setLoading(false);
      var jsonBody = getReqJsonModalGA4(bussinessName!,error.status,error.message,URL_CONSTANTS.get('URL_POST_SEND_OTP')!)
      TrackGoogleAnalyticsEvent('Api Call',GA4EventsNames.apiFailed,URL_CONSTANTS.get('URL_POST_SEND_OTP')!,jsonBody);
    }
  };
  const trackingOrder = async (
    order_id: string,
    red_if: string,
  ) => {
    setLoading(true);
    try {
      var res = await httpGet(
        `rjs/track/order?order=${order_id}&red_if=${red_if}`,bussinessName!
      );
      if (res.type === "success") {
        console.log(res.data);
        setTrackOrderVal(res.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  return {
    success,
    getCustomerInfo,
    customerInfo,
    trackOrderVal,
    orderPlaced,
    placeOrder,
    trackingOrder,
    loading,
  };
};
export default useCustomerInfo;
