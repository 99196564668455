import React from "react";
// import { ReactComponent as RecordIconSvg } from "../../../assets/images/record-icon.svg";
interface RecordSvgProps {
  height: number | string;
  width: number | string;
}
const RecordSvg: React.FC<RecordSvgProps> = (props: RecordSvgProps) => {
  const styles = getComputedStyle(document.documentElement);
  const bgColor = styles.getPropertyValue("--background-color-store");
  const { height, width } = props;
  return (
    <svg
      data-name="Layer 1"
      id="Layer_1"
      width={width}
      height={height}
      fill={bgColor}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <path
        className="cls-1"
        d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30Z"
      />
      <path
        className="cls-1"
        d="M16,9a7,7,0,1,0,7,7A7,7,0,0,0,16,9Zm0,12a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z"
      />
    </svg>
  );
};

export default RecordSvg;
