export const allLink = {
    restoByNameForToken :`resto/by/name${'outletName'}`,
    getBussinessData:'resto',
    getCategories:'categories',
    createCustomer:'create/customer?',
    loadReceipent:'customer/recipients?',
    loadAddress:'get/customer/address?',
    saveAddress:'save/customer/address?',
    saveGreetingMessage :'save/greeting/message?',
    deleteAddress:'delete/customer/main/address?',
    deleteReceiver:'`delete/recipient/${receiver_id}`',
    placeOrder:'confirmed/order?',
    getItemDetail :'`recipe?id=${itemId}`',
    sendOtp :'send/otp?',
    validOtp:'validate/otp?',
    
    
    }
    //  const URL_BASE_RJS = 'https://adminv1.meemorder.io/api/rjs/';
     const URL_BASE_RJS = 'rjs/';
     const BASE_URL ="https://adminv1.meemorder.io/api/";
    //  /set/customer/main/address?
     
     const URL_CONSTANTS = new Map<string, string>([
        ["URL_Get_Receipents", URL_BASE_RJS +"customer/recipients?"],
        ["URL_Get_TrackOrder1", URL_BASE_RJS + `track/order?order=8366&red_if=430`],
        ["URL_Get_TrackOrder", URL_BASE_RJS + `track/order?order=%s&red_if=%s`],
        ["URL_Get_Receipents", URL_BASE_RJS +"customer/recipients?"],
        ["URL_Get_TrackOrder1", URL_BASE_RJS + `track/order?order=8366&red_if=430`],
        ["URL_Bussienss_Recipe", URL_BASE_RJS + `business/recipe`],
        ["URL_Bussienss_Info", URL_BASE_RJS + `business/info`],
        ["URL_Update_Address", URL_BASE_RJS + `set/customer/main/address?`],
        ["URL_Bussienss_Categories", URL_BASE_RJS + `business/categories`],
        ["URL_GET_Customer_Discount", URL_BASE_RJS + `get/ordersForCustomerDiscountId?`],
        ["URL_POST_Validate_Otp", URL_BASE_RJS + `validate/otp?`],
        ["URL_POST_SEND_OTP", URL_BASE_RJS + `send/otp?`],
        ["URL_GET_CREATE_CUSTOMER", URL_BASE_RJS + `create/customer?`],
        // rjs/create/customer?
       ]);
       export  {URL_CONSTANTS};
    