import React, { useCallback, useEffect, useRef, useState } from "react";
// import SelecteDatePop from "./CreateNewCustomer";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import useSendOtp from "../../hooks/useSendOtp";
// import { useAppSelector } from "../../../../../hooks/useRedux";
import { ObjectKeyLng } from "../HomePage/utils";
import { OtpResData, OtpRespone } from "../utilis";
// import useCustomerInfo from "../../../../../hooks/useCustomerInfo";
import useCustomerInfo from "../../hooks/useCustomerInfo";
import VerificationInput from "react-verification-input";
import Loader from "../Loader";
import { translations } from "../../../assets/meemLabels";
import BackArrowSvg from "../components/BackArrow";
interface ValidateOtpResponse {
  onPressClose: () => void;
  onSuccess: (customerData: any) => void;
  selectedCC: string;
  otpRes: OtpRespone;
  customerName: string;
}
const PopPageValidateOtp: React.FC<ValidateOtpResponse> = (
  props: ValidateOtpResponse
) => {
  const { onPressClose, onSuccess, otpRes, selectedCC, customerName } = props;
  const {
    validOtpHandler,
    loading,
    customerData,
    sendOtpHandler,
    invalidOtp,
    otpData,
  } = useSendOtp();
  const { getCustomerInfo, customerInfo } = useCustomerInfo();
  const { bussinessName } = useParams();
  const navigate = useNavigate();
  const InputType = "tel";
  const [reqId, setReqId] = useState<string>(otpRes.data.req_id);
  const shopCartFromStrg = localStorage.getItem("singlePage/" + bussinessName);
  const shopCartStrg = localStorage.getItem("shopCart");
  const defaultLang: ObjectKeyLng =
    shopCartFromStrg !== null && JSON.parse(shopCartFromStrg).default_lang;
  const accessToken =
    shopCartFromStrg !== null && JSON.parse(shopCartFromStrg).accessToken;
  // const location = useLocation();
  // const otpRes = location.state.otpTemp;
  const shopName =
    shopCartFromStrg !== null && JSON.parse(shopCartFromStrg).name;
  const restoId =
    shopCartFromStrg !== null && JSON.parse(shopCartFromStrg).restoId;
  const [otpCode, setOtpCode] = useState<string>("");
  const [timer, setTimer] = useState(60);
  const [optFiled1, setOtpField1] = useState<string>("");

  const [optFiled2, setOtpField2] = useState<string>("");
  const [optFiled3, setOtpField3] = useState<string>("");
  const [showOtpErr, setShowOtpErr] = useState<boolean>(false);
  const [optFiled4, setOtpField4] = useState<string>("");
  // const { t } = useTranslation();
  const whatsImg = require("../../../assets/images/whatsapp.png");
  // const bussinessName = useAppSelector((state) => state.shopInfo.outletName);
  const shopDataLocal = localStorage.getItem("shop/" + bussinessName);
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const inputRef4 = useRef<HTMLInputElement>(null);
  const [showSelectDatePop, setShowSelectDate] = useState<boolean>(false);
  const [showPopConfirm, setShowPopConfirm] = useState<boolean>(false);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  const onPressCloseHandler = () => {
    // navigate(-1);
    onPressClose();
  };
  const resetTimer = async () => {
    await sendOtpHandler(
      otpRes.data.msisdn,
      selectedCC,
      restoId,
      shopName,
      defaultLang,
      accessToken
    );
    inputRef1.current?.focus();
    setOtpField1("");
    setOtpField2("");
    setOtpField3("");
    setOtpField4("");
    if (!timer) {
      setTimer(60);
    }
  };
  const functionValidateOtp = (value4: string) => {
    // const otpTemp: OtpResData = {
    //   data: {
    //     email: "dddd",
    //     id: 324,
    //     mobile_number: "923105599915",
    //     name: "Muhammad Abdullah Rehan",
    //   },
    //   message: "Customer profile data",
    //   type: "success",
    // };
    let otpCode = value4;
    validOtpHandler(
      // otpRes.data.req_id,
      reqId,
      otpRes.data.msisdn,
      otpCode,
      accessToken
    );
    // getCustomerInfo("03122222222", "Aaaa", accessToken);
    // localStorage.setItem("customerInfo", JSON.stringify(otpTemp.data));
  };
  const handleInputChange = (e: any, index: number) => {
    const limit = 1;
    var result = e.target.value.replace(/\D/g, "");
    // result = e.target.value.slice(0, limit);
    handleOtpChange(result, index);
    // console.log(e);
    //  setPhoneNumber(result);
  };
  const handleOtpChange = (
    value: any,
    // event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // const limit = 1;
    // let value = event.target.value.slice(0, limit);
    // setOtpCode(event.target.value);
    setShowError(false);
    if (value.length === 1) {
      switch (index) {
        case 1: {
          // if ()
          inputRef2.current?.focus();
          setOtpField1(value);
          break;
        }

        case 2: {
          inputRef3.current?.focus();
          setOtpField2(value);
          break;
        }
        case 3: {
          inputRef4.current?.focus();
          setOtpField3(value);
          break;
        }
        case 4: {
          setOtpField4(value);
          functionValidateOtp(value);
          // showSelectDateHandler(value);
          // showSelectDateHandler();
          // inputRef4.current?.focus();
          break;
        }
      }
    }
  };

  const showSelectDateHandler = async (value4: string) => {
    if (shopDataLocal !== null) {
      let temp: any = {};
      let parsedData = JSON.parse(shopDataLocal);
      temp = parsedData;
      // temp.customerInfo = otpRes.data;
      // var newShopData = {
      //   ...temp,
      //   customerInfo: otpRes.data,
      // };
      if (
        optFiled1 !== "" &&
        optFiled2 !== "" &&
        optFiled3 !== "" &&
        value4 !== ""
      ) {
        // getCustomerInfo("03122222222", otpRes?.data?.name, accessToken).then(
        //   (value) => {
        //     if (value) {
        //       setShowSelectDate(true);
        //     } else {
        //       // setShowPopConfirm(true);
        //       navigate(`/${bussinessName}/place-order`, { replace: true });
        //     }
        //   }
        // );
      } else {
        setShowError(true);
      }
      // console.log(newShopData, bussinessName);
      // localStorage.setItem("customerInfo", JSON.stringify(otpRes.data));
    }
  };
  const hideSelectDateHandler = () => {
    setShowSelectDate(false);
    setShowPopConfirm(false);
  };
  useEffect(() => {
    inputRef1.current?.focus();
  }, []);
  useEffect(() => {
    if (customerData !== undefined && customerData.type === "success") {
      // setShowSelectDate(true);
      getCustomerInfo(
        // "03122222222",
        customerData?.data.mobile_number,
        customerName,
        accessToken
      ).then((value) => {
        if (value) {
          onSuccess(customerData);
          onPressClose();
          // setShowSelectDate(true);
        } else {
          // setShowPopConfirm(true);
          // navigate(`/${bussinessName}/place-order`, { replace: true });
        }
      });
      var tempData = {
        ...customerData.data,
        name: customerName,
      };
      localStorage.setItem("customerInfo", JSON.stringify(tempData));
    }
  }, [customerData]);
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);
  useEffect(() => {
    if (customerInfo !== undefined) {
      onSuccess(customerInfo);
    }
  }, [customerInfo]);
  useEffect(() => {
    if (otpData !== undefined) {
      setReqId(otpData.data.req_id);
    }
  }, [otpData]);
  return (
    <div
      id="pop_cart_page3"
      className="popup pop_up_maps_main pop_main_menu_bg"
      lang={defaultLang}
    >
      <div className="popup_cart_full popup_from_bottom">
        <div className="content" id="">
          <div className="cart_head_container">
            <div className="container">
              <div className="header_cart_page">
                <div className="cart_heading">&nbsp;</div>

                <BackArrowSvg onPressClose={onPressCloseHandler} />
              </div>
            </div>
          </div>
          {/* <!-- content start here --> */}
          <div className="cart_content">
            <div className="cart_content_to_marg">
              <div className="cart_content_row">
                <h4 style={{ color: "#000000" }} className="">
                  {/* {t("verify_number")} */}
                  {translations.verify_number[defaultLang]}
                </h4>
                <img
                  src={whatsImg}
                  className="icon_sm"
                  alt=""
                  style={{ height: 35, width: 35 }}
                />
                <p className="light_grey_text res_font_size1">
                  {/* {t("send_verification_message")} +923105599915 */}
                  {translations.send_verification_message[defaultLang]}
                  {/* {otpRes.data.msisdn} */}
                </p>
                <div
                  style={{ opacity: "65%", fontSize: 12 }}
                  className="light_grey_text res_font_size1"
                >
                  {otpRes.data.msisdn}
                </div>
              </div>
              <VerificationInput
                validChars={"[0-9]{0,5}"}
                length={4}
                inputProps={{ type: InputType }}
                autoFocus
                onComplete={(value: string) => {
                  // alert(value);
                  functionValidateOtp(value);
                }}
                classNames={{
                  container: "input-otp-container",
                  character: "input-otp-character",
                  characterSelected: "input-otp-active",
                }}
              />
              {/* <div className="cart_content_row">
                <div className="" style={{ marginLeft: 20 }}>
                  <input
                    ref={inputRef1}
                    type="text"
                    value={optFiled1}
                    onChange={(eve) => {
                      handleInputChange(eve, 1);
                      setOtpCode((prev) => prev + eve.target.value);
                    }}
                    className={`cart_mobile_input_type_small ${
                      showError && "border-error"
                    }`}
                    name="number1"
                    onKeyDown={(e) => onKeyDown(e, 1)}
                    tabIndex={1}
                    maxLength={1}
                    autoComplete="off"
                  />
                  <input
                    ref={inputRef2}
                    type="text"
                    value={optFiled2}
                    // onKeyPress={
                    //   // (event) => event.charCode >= 47 && event.charCode <= 57
                    //   //  ||
                    //   // (event.charCode >= 97 && event.charCode <= 122)
                    // }
                    // value={optFiled2}
                    onChange={(eve) => {
                      handleInputChange(eve, 2);
                    }}
                    onKeyDown={(e) => onKeyDown(e, 2)}
                    className={`cart_mobile_input_type_small ${
                      showError && "border-error"
                    }`}
                    name="number2"
                    tabIndex={1}
                    maxLength={1}
                    autoComplete="off"
                  />
                  <input
                    ref={inputRef3}
                    value={optFiled3}
                    type="text"
                    // value={optFiled3}
                    onChange={(eve) => {
                      handleInputChange(eve, 3);
                    }}
                    className={`cart_mobile_input_type_small ${
                      showError && "border-error"
                    }`}
                    name="number3"
                    onKeyDown={(e) => onKeyDown(e, 3)}
                    onKeyPress={
                      (event) => event.charCode >= 47 && event.charCode <= 57
                      //  ||
                      // (event.charCode >= 97 && event.charCode <= 122)
                    }
                    tabIndex={1}
                    maxLength={1}
                    autoComplete="off"
                  />
                  <input
                    ref={inputRef4}
                    value={optFiled4}
                    type="text"
                    // value={optFiled4}
                    onChange={(eve) => {
                      handleInputChange(eve, 4);
                    }}
                    className={`cart_mobile_input_type_small ${
                      showError && "border-error"
                    }`}
                    name="text"
                    tabIndex={1}
                    onKeyDown={(e) => onKeyDown(e, 4)}
                    onKeyPress={
                      (event) => event.charCode >= 47 && event.charCode <= 57
                      //  ||
                      // (event.charCode >= 97 && event.charCode <= 122)
                    }
                    maxLength={1}
                    autoComplete="off"
                  />
                </div>
              </div> */}
              {showError && (
                <div
                  style={{ marginLeft: 20 }}
                  className={showError ? "show-error-message" : ""}
                >
                  <text>Pleae enter value</text>
                </div>
              )}
              {invalidOtp && (
                <div className="cart_content_row cart_content_to_marg">
                  <div style={{ color: "red" }}>
                    Entered code is not correct
                  </div>
                </div>
              )}
              <div className="cart_content_row cart_content_to_marg">
                {timer > 0 ? (
                  <div className="cart_content_alert">
                    {/* {t("verifitcation_code_error")} | {timer} */}
                    {translations.verifitcation_code_error[defaultLang]}|{" "}
                    {timer}
                  </div>
                ) : (
                  <button
                    className="cart_content_alert"
                    onClick={resetTimer}
                    disabled={loading}
                    style={{ marginLeft: "20%", padding: 5 }}
                  >
                    Click here to get new code
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopPageValidateOtp;
