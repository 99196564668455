import { httpPost } from "../../../setup/axios/axiosUtils";
import { useEffect, useState } from "react";
import {
  OtpRespone,
  OtpResData,
  getReqJsonModalGA4,
  TrackGoogleAnalyticsEvent,
  GA4EventsNames,
} from "../../pages/utilis";
import { URL_CONSTANTS } from "../../../setup/urlContants";
import { useParams } from "react-router-dom";
const useSendOtp = () => {
  const [loading, setLoading] = useState(false);
  const [otpData, setOtpData] = useState<OtpRespone>();
  const {bussinessName} = useParams();
  const [invalidOtp,setInvalid]=useState<boolean>(false);
  const[otpNotSendReason,setErrorMessage] = useState<string>('');
  const [customerData, setCustomerData] = useState<OtpResData>();

  const sendOtpHandler = async (mobile_number: string, country: string,bussinessId:string,bussinessName:string,bussinessLang:string,access_token:string) => {
    var success = false;
  
    var otpData :OtpRespone = {} as OtpRespone;
    setLoading(true);
    try {
      const res = await httpPost(
        bussinessName,
        URL_CONSTANTS.get('URL_POST_SEND_OTP')!,
        {
          id: bussinessId,
          mobile_number,
          name: bussinessName,
          lang: bussinessLang,
          country,
        },
        {},
        access_token,
      );
      console.log(res.type === "success");
      if (res.type === "success") {
        setOtpData(res);
        success = true;
        otpData= res.data;
      }
      else{
        success = false;
        // otpData= res.data;
      }
      setLoading(false);
    } catch (error: any) {
      if(error){
      setErrorMessage(error.response.data.message)
      }
      var jsonBody = getReqJsonModalGA4(bussinessName,error.status,error.message,URL_CONSTANTS.get('URL_POST_SEND_OTP')!)
         TrackGoogleAnalyticsEvent('Api Call',GA4EventsNames.apiFailed,URL_CONSTANTS.get('URL_POST_SEND_OTP')!,jsonBody);
      // alert(error.message);
      setLoading(false);
    }
    return {success,otpData}
  };
  const validOtpHandler = async (
    req_id: string,
    mobile_number: string,
    otp: string,
    access_token:string,
  ) => {
   
    setLoading(true);
    try {
      const res = await httpPost(
        bussinessName!,
        URL_CONSTANTS.get('URL_POST_Validate_Otp')!,
        {
          req_id,
          otp,
          mobile_number,
        },
        {},
        access_token
      );
      console.log(res);
      if (res.type === "success") {
        setCustomerData(res);
      }
      if(res.type === 'error'){
        setInvalid(true);
      }
      setLoading(false);
    } catch (error: any) {
      // if(res.type === 'error'){
        setInvalid(true);
      // }
      console.log(error.message);
      var jsonBody = getReqJsonModalGA4(bussinessName!,error.status,error.message,URL_CONSTANTS.get('URL_POST_SEND_OTP')!)
      TrackGoogleAnalyticsEvent('Api Call',GA4EventsNames.apiFailed,URL_CONSTANTS.get('URL_POST_SEND_OTP')!,jsonBody);
      // alert(error.message);
      setLoading(false);
    }
  };
  return {
    sendOtpHandler,
    validOtpHandler,
    invalidOtp,
    loading,
    otpData,
    otpNotSendReason,
    customerData,
  };
};
export default useSendOtp;
