import React from "react";
interface Props {
  siteLogo: string | undefined;
  bussinessName: string;
}
const Footer: React.FC<Props> = (props: Props) => {
  const { siteLogo, bussinessName } = props;
  return (
    <div className="landscape_logo">
      <img src={siteLogo} className="landscape_logo_img" alt="" />
      <div className="landscape_logo_text">{bussinessName}</div>
    </div>
  );
};

export default Footer;
